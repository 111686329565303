import React, { useEffect, useState } from "react";
import { CSVDownload } from "react-csv";
import Layout from "../../components/Layout";
import { deleteContact, getContacts } from "../../services/contactServices";
import { useSelector } from "react-redux";

const ScreenContacts = () => {
  const { contacts } = useSelector((state) => state.contact);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      getContacts(true);
    };
    fetchData();
  }, []);

  const handleDeleteClick = (data) => {
    deleteContact(data);
  };

  const handleExportClick = () => {
    const dataForExport = contacts.map((contact, index) => ({
      "Sl. No.": index + 1,
      "First Name": contact.firstName,
      "Last Name": contact.lastName,
      InquiryType: contact.inquiryType,
      Email: contact.email,
      Phone: contact.phone,
      Message: contact.message,
      Source: contact.source,
    }));
    setCsvData([
      {
        "Sl. No.": "Sl. No.",
        "First Name": "First Name",
        "Last Name": "Last Name",
        InquiryType: "InquiryType",
        Email: "Email",
        Phone: "Phone",
        Message: "Message",
        Source: "Source",
      },
      ...dataForExport,
    ]);
  };

  return (
    <Layout title="contact">
      <div className="contactmaincontainer">
        <div className="linkheadingcontainer">
          <h1>Admin / Contacts</h1>
        </div>
        <div>
          <section className="p-3 sm:p-5">
            <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
              <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                  <div className="w-full md:w-1/2">
                    <form className="flex items-center">
                      <label htmlFor="simple-search" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          required=""
                          placeholder="Search"
                          onChange={(e) => getContacts(true, e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleExportClick}
                      className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover-bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                    >
                      Export
                    </button>
                    {csvData.length > 0 && (
                      <CSVDownload data={csvData} filename={"contacts.csv"} />
                    )}
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          Sl. No.
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Name
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Inquiry Type
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Email
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Phone
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Source
                        </th>

                        <th scope="col" className="px-4 py-3 text-center">
                          Message
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((data, index) => {
                        return (
                          <tr
                            className="border-b dark:border-gray-700"
                            key={data.id}
                          >
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {index + 1}
                            </th>
                            <td className="px-4 py-3">
                              {data.firstName + " " + data.lastName}
                            </td>
                            <td className="px-4 py-3">{data.inquiryType}</td>
                            <td className="px-4 py-3">{data.email}</td>
                            <td className="px-4 py-3">{data.phone}</td>
                            <td className="px-4 py-3">{data.source}</td>
                            <td className="px-4 py-3">{data.message}</td>

                            <td className="px-4 py-3 flex items-center justify-center">
                              <button
                                type="button"
                                className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover-bg-red-600 dark:focus:ring-red-900"
                                onClick={() => handleDeleteClick(data.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <nav
                  className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
                  aria-label="Table navigation"
                ></nav>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenContacts;
