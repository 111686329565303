import { createSlice } from "@reduxjs/toolkit";
import LocalStorageKeys from "../core/localstorageKeys";

const adminInitState = {
  token: localStorage.getItem(LocalStorageKeys.adminToken),
  isLoading: false,
  isLoggedIn: localStorage.getItem(LocalStorageKeys.adminToken) ? true : false,
  language: localStorage.getItem(LocalStorageKeys.language) ?? "ENGLISH",
  admins: [],
  admin: {},
  profile: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState: adminInitState,
  reducers: {
    switchLanguage: (state, action) => {
      console.log(action.payload);
      if (["ENGLISH", "ARABIC"].includes(action.payload)) {
        state.language = action.payload;
        localStorage.setItem(LocalStorageKeys.language, action.payload);
      }
    },
    changeAdminLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setAdminById: (state, action) => {
      state.admin = action.payload;
      state.isLoading = false;
    },
    setAdminLoggedIn: (state, action) => {
      if (!state.isLoggedIn) {
        localStorage.setItem(LocalStorageKeys.adminToken, action.payload);
        state.token = action.payload;
        state.isLoggedIn = true;
        state.isLoading = false;
      }
    },
    setAdminLogout: (state) => {
      if (state.isLoggedIn) {
        localStorage.removeItem(LocalStorageKeys.adminToken);
        state.isLoggedIn = false;
        state = adminInitState;
      }
    },
    setAdminProfile: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    setAdminsState: (state, action) => {
      if (state.admins.length <= 0 || action.payload.refresh) {
        state.admins = action.payload.admins || state.admins;
      }
      state.isLoading = false;
    },
    addAdminState: (state, action) => {
      state.admins.unshift(action.payload);
      state.isLoading = false;
    },
    editAdminState: (state, action) => {
      state.admins = state.admins.map((admin) => {
        if (action.payload.id === admin.id) {
          admin = action.payload;
        }
        return admin;
      });
      state.isLoading = false;
    },
    changeAdminStatusState: (state, action) => {
      state.admins = state.admins.map((admin) => {
        if (
          action.payload.id === admin.id &&
          action.payload.status !== admin.status
        ) {
          admin.status = action.payload.status;
        }
        return admin;
      });
      state.isLoading = false;
    },
    deleteAdminState: (state, action) => {
      state.admins = state.admins.filter(
        (admin) => admin.id !== action.payload
      );
      state.isLoading = false;
    },
    clearAdminState: (state) => {
      state = adminInitState;
    },
  },
});

export const {
  switchLanguage,
  setAdminLoggedIn,
  setAdminLogout,
  setAdminProfile,
  changeAdminLoading,
  setAdminById,
  setAdminsState,
  addAdminState,
  editAdminState,
  changeAdminStatusState,
  deleteAdminState,
  clearAdminState,
} = adminSlice.actions;

export default adminSlice.reducer;
