import { createSlice } from "@reduxjs/toolkit";

const clientInitState = {
  isLoading: false,
  clients: [],
  client: {},
};

const clientSlice = createSlice({
  name: "client",
  initialState: clientInitState,
  reducers: {
    changeClientLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setClientById: (state, action) => {
      state.client = action.payload;
      state.isLoading = false;
    },
    setClientsState: (state, action) => {
      if (state.clients.length <= 0 || action.payload.refresh) {
        state.clients = action.payload.clients || state.clients;
      }
      state.isLoading = false;
    },
    addClientState: (state, action) => {
      state.clients.unshift(action.payload);
      state.isLoading = false;
    },
    editClientState: (state, action) => {
      state.clients = state.clients.map((client) => {
        if (action.payload.id === client.id) {
          client = action.payload;
        }
        return client;
      });
      state.isLoading = false;
    },
    changeClientVisibilityState: (state, action) => {
      state.clients = state.clients.map((client) => {
        if (
          action.payload.id === client.id &&
          action.payload.visibility !== client.visibility
        ) {
          client.visibility = action.payload.visibility;
        }
        return client;
      });
      state.isLoading = false;
    },
    deleteClientState: (state, action) => {
      state.clients = state.clients.filter(
        (client) => client.id !== action.payload
      );
      state.isLoading = false;
    },
    clearClientState: (state) => {
      state = clientInitState;
    },
  },
});

export const {
  changeClientLoading,
  setClientsState,
  setClientById,
  addClientState,
  editClientState,
  changeClientVisibilityState,
  deleteClientState,
  clearClientState,
} = clientSlice.actions;

export default clientSlice.reducer;
