import { createSlice } from "@reduxjs/toolkit";

const careerInitState = {
  isLoading: false,
  careers: [],
  career: {},
};

const careerSlice = createSlice({
  name: "career",
  initialState: careerInitState,
  reducers: {
    changeCareerLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setCareerById: (state, action) => {
      state.career = action.payload;
      state.isLoading = false;
    },
    setCareersState: (state, action) => {
      if (state.careers.length <= 0 || action.payload.refresh) {
        state.careers = action.payload.careers || state.careers;
      }
      state.isLoading = false;
    },
    addCareerState: (state, action) => {
      state.careers.unshift(action.payload);
      state.isLoading = false;
    },
    editCareerState: (state, action) => {
      state.careers = state.careers.map((career) => {
        if (action.payload.id === career.id) {
          career = action.payload;
        }
        return career;
      });
      state.isLoading = false;
    },
    changeCareerVisibilityState: (state, action) => {
      state.careers = state.careers.map((career) => {
        if (
          action.payload.id === career.id &&
          action.payload.visibility !== career.visibility
        ) {
          career.visibility = action.payload.visibility;
        }
        return career;
      });
      state.isLoading = false;
    },
    deleteCareerState: (state, action) => {
      // console.log(current(state));
      state.careers = state.careers.filter(
        (career) => career.id !== action.payload
      );
      state.isLoading = false;
    },
    clearCareerState: (state) => {
      state = careerInitState;
    },
  },
});

export const {
  changeCareerLoading,
  setCareersState,
  setCareerById,
  addCareerState,
  editCareerState,
  changeCareerVisibilityState,
  deleteCareerState,
  clearCareerState,
} = careerSlice.actions;

export default careerSlice.reducer;
