import React, { useEffect, useState } from "react";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import {
  addCareer,
  editCareer,
  getCareerById,
} from "../../../services/careerServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddCareer = () => {
  const navigate = useNavigate();
  const { careerId } = useParams();
  const { language } = useSelector((state) => state.admin);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [applyLink, setApplyLink] = useState("");
  const [careerType, setCareerType] = useState("FULL_TIME");
  const [selectedImage, setSelectedImage] = useState();
  const [careerImage, setCareerImage] = useState(null);

  useEffect(() => {
    const getCareer = async () => {
      if (careerId) {
        const career = await getCareerById(careerId, language);
        setTitle(career.title);
        setCareerType(career.type);
        setLocation(career.location);
        setApplyLink(career.applyLink);
        setCareerImage(ApiEndpoints.imageURL + career.image);
      }
    };
    getCareer();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setCareerImage(null);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("type", careerType);
      formData.append("location", location);
      formData.append("image", selectedImage);
      formData.append("applyLink", applyLink);

      let resp = false;
      if (careerId) {
        resp = await editCareer(careerId, formData, language);
      } else {
        resp = await addCareer(formData, language);
      }

      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };
  const handleClick = () => {
    navigate(KRoutes.careers);
  };

  return (
    <Layout title="career">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / {careerId ? "Edit" : "Add"} Career</h1>
        </div>

        <div className="bg-white-500  flex items-start justify-start">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Career Details</h2>

            <label htmlFor="title" className="block mb-2">
              Career Title:
            </label>
            <input
              type="text"
              style={{ width: "100%" }}
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="CareerType" className="block mb-2">
              Career Type:
            </label>
            <select
              id="CareerType"
              style={{ width: "100%" }}
              value={careerType}
              onChange={(e) => setCareerType(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            >
              <option value="FULL_TIME">Full Time</option>
              <option value="PART_TIME">Part Time</option>
              <option value="FREELANCE">Freelance</option>
              <option value="INTERNSHIP">Internship</option>
            </select>

            <label htmlFor="Location" className="block mb-2">
              Location:
            </label>
            <input
              type="text"
              id="Location"
              style={{ width: "100%" }}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="ApplyLink" className="block mb-2">
              Apply Link:
            </label>
            <input
              type="url"
              id="ApplyLink"
              style={{ width: "100%" }}
              value={applyLink}
              onChange={(e) => setApplyLink(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <input type="file" onChange={handleImageChange} />
            {(selectedImage || careerImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : careerImage
                }
                alt="Selected Partner"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddCareer;
