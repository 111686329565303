import { createSlice } from "@reduxjs/toolkit";

const solutionInitState = {
  isLoading: false,
  solutions: [],
  solution: {},
};

const solutionSlice = createSlice({
  name: "solution",
  initialState: solutionInitState,
  reducers: {
    changeSolutionLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setSolutionById: (state, action) => {
      state.solution = action.payload;
      state.isLoading = false;
    },
    setSolutionsState: (state, action) => {
      if (state.solutions.length <= 0 || action.payload.refresh) {
        state.solutions = action.payload.solutions || state.solutions;
      }
      state.isLoading = false;
    },
    addSolutionState: (state, action) => {
      state.solutions.unshift(action.payload);
      state.isLoading = false;
    },
    editSolutionState: (state, action) => {
      state.solutions = state.solutions.map((solution) => {
        if (action.payload.id === solution.id) {
          solution = action.payload;
        }
        return solution;
      });
      state.isLoading = false;
    },
    changeSolutionVisibilityState: (state, action) => {
      state.solutions = state.solutions.map((solution) => {
        if (
          action.payload.id === solution.id &&
          action.payload.visibility !== solution.visibility
        ) {
          solution.visibility = action.payload.visibility;
        }
        return solution;
      });
      state.isLoading = false;
    },
    deleteSolutionState: (state, action) => {
      state.solutions = state.solutions.filter(
        (solution) => solution.id !== action.payload
      );
      state.isLoading = false;
    },
    clearSolutionState: (state) => {
      state = solutionInitState;
    },
  },
});

export const {
  changeSolutionLoading,
  setSolutionsState,
  setSolutionById,
  addSolutionState,
  editSolutionState,
  changeSolutionVisibilityState,
  deleteSolutionState,
  clearSolutionState,
} = solutionSlice.actions;

export default solutionSlice.reducer;
