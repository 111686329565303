import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeSolutionBodyLoading,
  setSolutionBodiesState,
  setSolutionBodyById,
  addSolutionBodyState,
  editSolutionBodyState,
  changeSolutionBodyVisibilityState,
  deleteSolutionBodyState,
} from "../redux/solutionBodyReducer";

/**
 * To get a  solutionBodies from server
 */
export const getSolutionBodiesBySolutionId = async (
  id,
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSolutionBodyLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.solutionBodysEn + id
        : ApiEndpoints.solutionBodysAr + id,
      {
        params: { title: query.trim(), limit: -1 },
      }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setSolutionBodiesState({ solutionBodies: resp.data.results, refresh })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionBodyLoading(false));
  }
};

/**
 * To solutionBody from server
 */
export const getSolutionBodyById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeSolutionBodyLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.solutionBodyEn + "/" + id
        : ApiEndpoints.solutionBodyAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSolutionBodyById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionBodyLoading(false));
  }
};

/**
 * To add a new  solutionBody
 */
export const addSolutionBody = async (
  solutionBodyData,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeSolutionBodyLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH"
        ? ApiEndpoints.solutionBodyEn
        : ApiEndpoints.solutionBodyAr,
      solutionBodyData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addSolutionBodyState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionBodyLoading(false));
  }
};

/**
 * To edit a new  solutionBody
 */
export const editSolutionBody = async (
  id,
  solutionBodyData,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeSolutionBodyLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.solutionBodyEn + "/" + id
        : ApiEndpoints.solutionBodyAr + "/" + id,
      solutionBodyData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editSolutionBodyState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionBodyLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeSolutionBodyVisibility = async (
  id,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeSolutionBodyLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.solutionBodyVisibilityEn + id
        : ApiEndpoints.solutionBodyVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSolutionBodyVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionBodyLoading(false));
  }
};

/**
 * To delete the solutionBody
 */
export const deleteSolutionBody = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeSolutionBodyLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.solutionBodyEn + "/" + id
        : ApiEndpoints.solutionBodyAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteSolutionBodyState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionBodyLoading(false));
  }
};
