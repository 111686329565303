import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeAwardLoading,
  setAwardsState,
  setAwardById,
  addAwardState,
  editAwardState,
  changeAwardVisibilityState,
  deleteAwardState,
} from "../redux/awardReducer";

/**
 * To get a  awards from server
 */
export const getAwards = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAwardLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH" ? ApiEndpoints.awardEn : ApiEndpoints.awardAr,
      { params: { title: query.trim(), limit: -1 } }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAwardsState({ awards: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardLoading(false));
  }
};

/**
 * To award from server
 */
export const getAwardById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.awardEn + "/" + id
        : ApiEndpoints.awardAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    console.log("Awardstate : ", resp.data.results);

    // Update to Store
    dispatch(setAwardById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardLoading(false));
  }
};

/**
 * To add a new  award
 */
export const addAward = async (awardData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH" ? ApiEndpoints.awardEn : ApiEndpoints.awardAr,
      awardData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addAwardState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardLoading(false));
  }
};

/**
 * To edit a new  award
 */
export const editAward = async (id, awardData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.awardEn + "/" + id
        : ApiEndpoints.awardAr + "/" + id,
      awardData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editAwardState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeAwardVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.awardVisibilityEn + id
        : ApiEndpoints.awardVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeAwardVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardLoading(false));
  }
};

/**
 * To delete the award
 */
export const deleteAward = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.awardEn + "/" + id
        : ApiEndpoints.awardAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteAwardState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardLoading(false));
  }
};
