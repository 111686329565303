import React, { useEffect, useState } from "react";
import "../styles/dashboard/dashboard.scss";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { KRoutes } from "../routers/routers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Layout = ({ children, title }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.admin);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(KRoutes.login);
    }
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className="grid-containernormal">
      <Helmet>
      <meta http-equiv="X-Frame-Options" content="DENY" />
        <meta
          http-equiv="Content-Security-Policy"
          content="frame-ancestors 'none'"
        />
        <meta http-equiv="X-Powered-By" content="" />
        <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
         
      </Helmet>
      <Header OpenSidebar={OpenSidebar} />
      <div>{children}</div>
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
        title={title}
      />
    </div>
  );
};

export default Layout;
