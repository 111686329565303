import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeSubscriberLoading,
  setSubscribersState,
  setSubscriberById,
  addSubscriberState,
  editSubscriberState,
  changeSubscriberVisibilityState,
  deleteSubscriberState,
} from "../redux/subscriberReducer";

/**
 * To get a  subscribers from server
 */
export const getSubscribers = async (refresh = false, query = "") => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSubscriberLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.subscriber, {
      params: { email: query.trim(), limit: -1 },
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSubscribersState({ subscribers: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSubscriberLoading(false));
  }
};

/**
 * To subscriber from server
 */
export const getSubscriberById = async (id) => {
  try {
    // Set Loading
    dispatch(changeSubscriberLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.subscriber + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSubscriberById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSubscriberLoading(false));
  }
};

/**
 * To add a new  subscriber
 */
export const addSubscriber = async (subscriberData) => {
  try {
    // Set Loading
    dispatch(changeSubscriberLoading(true));

    // API Fetching
    const resp = await httpClient().post(
      ApiEndpoints.subscriber,
      subscriberData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addSubscriberState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSubscriberLoading(false));
  }
};

/**
 * To edit a new  subscriber
 */
export const editSubscriber = async (id, subscriberData) => {
  try {
    // Set Loading
    dispatch(changeSubscriberLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.subscriber + "/" + id,
      subscriberData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editSubscriberState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSubscriberLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeSubscriberVisibility = async (id) => {
  try {
    // Set Loading
    dispatch(changeSubscriberLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.subscriber + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSubscriberVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSubscriberLoading(false));
  }
};

/**
 * To delete the subscriber
 */
export const deleteSubscriber = async (id) => {
  try {
    // Set Loading
    dispatch(changeSubscriberLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.subscriber + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteSubscriberState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSubscriberLoading(false));
  }
};
