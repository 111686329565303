import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRouter";

// Admin
import ScreenLogin from "../screens/Admin/ScreenLogin";
import ScreenAdmins from "../screens/Admin/ScreenAdmins";
import ScreenAddAdmin from "../screens/Admin/ScreenAddAdmin";

// Awards
import EnglishScreenAwards from "../screens/English Screens/Award/ScreenAwards";
import EnglishScreenAddAward from "../screens/English Screens/Award/ScreenAddAward";

// Award Bodies
import EnglishScreenAwardBodies from "../screens/English Screens/Award Body/ScreenAwardBodies";
import EnglishScreenAddAwardBody from "../screens/English Screens/Award Body/ScreenAddAwardBody";

// Careers
import EnglishScreenCareers from "../screens/English Screens/Career/ScreenCareers";
import EnglishScreenAddCareer from "../screens/English Screens/Career/ScreenAddCareer";

// Clients
import EnglishScreenClients from "../screens/English Screens/Client/ScreenClients";
import EnglishScreenAddClient from "../screens/English Screens/Client/ScreenAddClient";

// Contacts
import ScreenContacts from "../screens/Contact/ScreenContacts";

// Industries
import EnglishScreenIndustries from "../screens/English Screens/Industry/ScreenIndustries";
import EnglishScreenAddIndustry from "../screens/English Screens/Industry/ScreenAddIndustry";

// InsightNews
import EnglishScreenInsightNews from "../screens/English Screens/Insight News/ScreenInsightNews";
import EnglishScreenAddInsightNews from "../screens/English Screens/Insight News/ScreenAddInsightNews";

// InsightVideos
import EnglishScreenInsightVideos from "../screens/English Screens/Insight Video/ScreenInsightVideos";
import EnglishScreenAddInsightVideo from "../screens/English Screens/Insight Video/ScreenAddInsightVideo";

// Partners
import EnglishScreenPartners from "../screens/English Screens/Partner/ScreenPartners";
import EnglishScreenAddPartner from "../screens/English Screens/Partner/ScreenAddPartner";

// Services
import EnglishScreenServices from "../screens/English Screens/Service/ScreenServices";
import EnglishScreenAddService from "../screens/English Screens/Service/ScreenAddService";

//Solutions
import EnglishScreenSolutions from "../screens/English Screens/Solution/ScreenSolutions";
import EnglishScreenAddSolution from "../screens/English Screens/Solution/ScreenAddSolution";

// Solution Bodies
import EnglishScreenSolutionBodies from "../screens/English Screens/Solution Body/ScreenSolutionBodies";
import EnglishScreenAddSolutionBody from "../screens/English Screens/Solution Body/ScreenAddSolutionBody";

// Subsribers
import ScreenSubscribers from "../screens/Subscriber/ScreenSubscribers";

// Testimonials
import EnglishScreenTestimonials from "../screens/English Screens/Testimonial/ScreenTestimonials";
import EnglishScreenAddTestimonial from "../screens/English Screens/Testimonial/ScreenAddTestimonial";

// Error
import ScreenError from "../screens/404/ScreenError";

export const KRoutes = {
  // Admin
  home: "/",
  login: "/login",

  // Admins
  admins: "/admins",
  addAdmin: "/admin",
  editAdmin: "/admin/",

  // Awards
  awards: "/awards",
  addAward: "/award",
  editAward: "/award/",

  // Award Bodies
  awardBodies: "/award/award-bodies/",
  addAwardBody: "/award/award-body/",
  editAwardBody: "/award/award-body/",

  //  Careers
  careers: "/careers",
  addCareer: "/career",
  editCareer: "/career/",

  //  Clients
  clients: "/clients",
  addClient: "/client",
  editClient: "/client/",

  // Contacts
  contacts: "/contacts",

  //  Industries
  industries: "/industries",
  addIndustry: "/industry",
  editIndustry: "/industry/",

  //  Insight News
  insightNews: "/view-insight-news",
  addInsightNews: "/insight-news",
  editInsightNews: "/insight-news/",

  //  Insight Videos
  insightVideos: "/insight-videos",
  addInsightVideo: "/insight-video",
  editInsightVideo: "/insight-video/",

  // Partner
  partners: "/partners",
  addPartner: "/partner",
  editPartner: "/partner/",

  // Service
  services: "/services",
  addService: "/service",
  editService: "/service/",

  // Solution
  solutions: "/solutions",
  addSolution: "/solution/",
  editSolution: "/solution/",

  // Solution Bodies
  solutionBodies: "/solution/bodies/",
  addSolutionBody: "/solution/body/",
  editSolutionBody: "/solution/body/",

  // Subscriber
  subscribers: "/subscribers",

  // Testimonial
  testimonials: "/testimonials",
  addTestimonial: "/testimonial",
  editTestimonial: "/testimonial/",
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Admin Page Start */}
        <Route path={KRoutes.home} element={<Navigate to={KRoutes.login} />} />
        <Route path={KRoutes.login} element={<ScreenLogin />} />

        <Route
          path={KRoutes.admins}
          element={<PrivateRoute component={<ScreenAdmins />} />}
        />

        <Route
          path={KRoutes.addAdmin}
          element={<PrivateRoute component={<ScreenAddAdmin />} />}
        />

        <Route
          path={KRoutes.editAdmin + ":adminId"}
          element={<PrivateRoute component={<ScreenAddAdmin />} />}
        />

        {/* Admin End */}
        {/* Award Page Start */}

        <Route
          path={KRoutes.awards}
          element={<PrivateRoute component={<EnglishScreenAwards />} />}
        />

        <Route
          path={KRoutes.addAward}
          element={<PrivateRoute component={<EnglishScreenAddAward />} />}
        />

        <Route
          path={KRoutes.editAward + ":awardId"}
          element={<PrivateRoute component={<EnglishScreenAddAward />} />}
        />

        <Route
          path={KRoutes.awardBodies + ":awardId"}
          element={<PrivateRoute component={<EnglishScreenAwardBodies />} />}
        />

        <Route
          path={KRoutes.addAwardBody + ":awardId"}
          element={<PrivateRoute component={<EnglishScreenAddAwardBody />} />}
        />

        <Route
          path={KRoutes.editAwardBody + ":awardId/" + ":awardBodyId"}
          element={<PrivateRoute component={<EnglishScreenAddAwardBody />} />}
        />

        {/* Award End */}
        {/* Career Page Start */}

        <Route
          path={KRoutes.careers}
          element={<PrivateRoute component={<EnglishScreenCareers />} />}
        />

        <Route
          path={KRoutes.addCareer}
          element={<PrivateRoute component={<EnglishScreenAddCareer />} />}
        />

        <Route
          path={KRoutes.editCareer + ":careerId"}
          element={<PrivateRoute component={<EnglishScreenAddCareer />} />}
        />

        {/* Careeer End */}
        {/* Client Page Start */}

        <Route
          path={KRoutes.clients}
          element={<PrivateRoute component={<EnglishScreenClients />} />}
        />

        <Route
          path={KRoutes.addClient}
          element={<PrivateRoute component={<EnglishScreenAddClient />} />}
        />

        <Route
          path={KRoutes.editClient + ":clientId"}
          element={<PrivateRoute component={<EnglishScreenAddClient />} />}
        />

        {/* Client End */}
        {/* Contact Page Start */}

        <Route
          path={KRoutes.contacts}
          element={<PrivateRoute component={<ScreenContacts />} />}
        />

        {/* Contact End */}
        {/* Industry Page Start */}

        <Route
          path={KRoutes.industries}
          element={<PrivateRoute component={<EnglishScreenIndustries />} />}
        />

        <Route
          path={KRoutes.addIndustry}
          element={<PrivateRoute component={<EnglishScreenAddIndustry />} />}
        />

        <Route
          path={KRoutes.editIndustry + ":industryId"}
          element={<PrivateRoute component={<EnglishScreenAddIndustry />} />}
        />

        {/* Industry End */}
        {/* InsightNews Page Start */}

        <Route
          path={KRoutes.insightNews}
          element={<PrivateRoute component={<EnglishScreenInsightNews />} />}
        />

        <Route
          path={KRoutes.addInsightNews}
          element={<PrivateRoute component={<EnglishScreenAddInsightNews />} />}
        />

        <Route
          path={KRoutes.editInsightNews + ":insightNewsId"}
          element={<PrivateRoute component={<EnglishScreenAddInsightNews />} />}
        />

        {/* InsightNews End */}
        {/* InsightVideos Page Start */}

        <Route
          path={KRoutes.insightVideos}
          element={<PrivateRoute component={<EnglishScreenInsightVideos />} />}
        />

        <Route
          path={KRoutes.addInsightVideo}
          element={
            <PrivateRoute component={<EnglishScreenAddInsightVideo />} />
          }
        />

        <Route
          path={KRoutes.editInsightVideo + ":insightVideoId"}
          element={
            <PrivateRoute component={<EnglishScreenAddInsightVideo />} />
          }
        />

        {/* InsightVideos End */}
        {/* Partner Page Start */}

        <Route
          path={KRoutes.partners}
          element={<PrivateRoute component={<EnglishScreenPartners />} />}
        />

        <Route
          path={KRoutes.addPartner}
          element={<PrivateRoute component={<EnglishScreenAddPartner />} />}
        />

        <Route
          path={KRoutes.editPartner + ":partnerId"}
          element={<PrivateRoute component={<EnglishScreenAddPartner />} />}
        />

        {/* Partner End */}
        {/* Service Page Start */}

        <Route
          path={KRoutes.services}
          element={<PrivateRoute component={<EnglishScreenServices />} />}
        />

        <Route
          path={KRoutes.addService}
          element={<PrivateRoute component={<EnglishScreenAddService />} />}
        />

        <Route
          path={KRoutes.editService + ":serviceId"}
          element={<PrivateRoute component={<EnglishScreenAddService />} />}
        />

        {/* Service End */}
        {/* Solution Page Start */}

        <Route
          path={KRoutes.solutions}
          element={<PrivateRoute component={<EnglishScreenSolutions />} />}
        />

        <Route
          path={KRoutes.addSolution}
          element={<PrivateRoute component={<EnglishScreenAddSolution />} />}
        />

        <Route
          path={KRoutes.editSolution + ":solutionId"}
          element={<PrivateRoute component={<EnglishScreenAddSolution />} />}
        />

        {/* Solution End */}
        {/* Solution Body Page Start */}

        <Route
          path={KRoutes.solutionBodies + ":solutionId"}
          element={<PrivateRoute component={<EnglishScreenSolutionBodies />} />}
        />

        <Route
          path={KRoutes.addSolutionBody + ":solutionId"}
          element={
            <PrivateRoute component={<EnglishScreenAddSolutionBody />} />
          }
        />

        <Route
          path={KRoutes.editSolutionBody + ":solutionId/" + ":solutionBodyId"}
          element={
            <PrivateRoute component={<EnglishScreenAddSolutionBody />} />
          }
        />

        {/* Solution Body End */}
        {/* Subscriber Page Start */}

        <Route
          path={KRoutes.subscribers}
          element={<PrivateRoute component={<ScreenSubscribers />} />}
        />

        {/* Subscriber End */}
        {/* Testimonial Page Start */}

        <Route
          path={KRoutes.testimonials}
          element={<PrivateRoute component={<EnglishScreenTestimonials />} />}
        />

        <Route
          path={KRoutes.addTestimonial}
          element={<PrivateRoute component={<EnglishScreenAddTestimonial />} />}
        />

        <Route
          path={KRoutes.editTestimonial + ":testimonialId"}
          element={<PrivateRoute component={<EnglishScreenAddTestimonial />} />}
        />

        {/* Testimonial End */}
        {/* 404 Page Start */}

        <Route path="*" element={<ScreenError />} />

        {/* 404 End */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
