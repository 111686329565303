import React, { useEffect, useState } from "react";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import {
  addIndustry,
  editIndustry,
  getIndustryById,
} from "../../../services/industryServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddIndustries = () => {
  const navigate = useNavigate();
  const { industryId } = useParams();
  const { language } = useSelector((state) => state.admin);

  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [industryImage, setIndustryImage] = useState();

  useEffect(() => {
    const getIndustry = async () => {
      if (industryId) {
        const industry = await getIndustryById(industryId, language);
        setName(industry.name);
        setIndustryImage(ApiEndpoints.imageURL + industry.image);
      }
    };
    getIndustry();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", selectedImage);
      let resp = false;
      if (industryId) {
        resp = await editIndustry(industryId, formData, language);
      } else {
        resp = await addIndustry(formData, language);
      }

      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };

  const handleClick = () => {
    navigate(KRoutes.industries);
  };

  return (
    <Layout title="industrieslogos">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / Add Industries</h1>
        </div>

        <div className=" bg-white-500  flex items-start justify-start">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Industries Details</h2>
            <label htmlFor="name" className="block mb-2">
              Industry Name:
            </label>
            <input
              type="text"
              id="name"
              style={{ width: "100%" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />
            <input type="file" onChange={handleImageChange} />
            {(selectedImage || industryImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : industryImage
                }
                alt="Selected Industries"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddIndustries;
