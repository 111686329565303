import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changePartnerLoading,
  setPartnersState,
  setPartnerById,
  addPartnerState,
  editPartnerState,
  changePartnerVisibilityState,
  deletePartnerState,
} from "../redux/partnerReducer";

/**
 * To get a  partners from server
 */
export const getPartners = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changePartnerLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH" ? ApiEndpoints.partnerEn : ApiEndpoints.partnerAr,
      { params: { name: query.trim(), limit: -1 } }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setPartnersState({ partners: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changePartnerLoading(false));
  }
};

/**
 * To partner from server
 */
export const getPartnerById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changePartnerLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.partnerEn + "/" + id
        : ApiEndpoints.partnerAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setPartnerById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changePartnerLoading(false));
  }
};

/**
 * To add a new  partner
 */
export const addPartner = async (partnerData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changePartnerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH" ? ApiEndpoints.partnerEn : ApiEndpoints.partnerAr,
      partnerData
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addPartnerState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changePartnerLoading(false));
  }
};

/**
 * To edit a new  partner
 */
export const editPartner = async (id, partnerData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changePartnerLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.partnerEn + "/" + id
        : ApiEndpoints.partnerAr + "/" + id,
      partnerData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editPartnerState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changePartnerLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changePartnerVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changePartnerLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.partnerVisibilityEn + id
        : ApiEndpoints.partnerVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changePartnerVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changePartnerLoading(false));
  }
};

/**
 * To delete the partner
 */
export const deletePartner = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changePartnerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.partnerEn + "/" + id
        : ApiEndpoints.partnerAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    console.log(id);
    dispatch(deletePartnerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changePartnerLoading(false));
  }
};
