import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeTestimonialLoading,
  setTestimonialsState,
  setTestimonialById,
  addTestimonialState,
  editTestimonialState,
  changeTestimonialVisibilityState,
  deleteTestimonialState,
} from "../redux/testimonialReducer";

/**
 * To get a  testimonials from server
 */
export const getTestimonials = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeTestimonialLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.testimonialEn
        : ApiEndpoints.testimonialAr,
      {
        params: { title: query.trim(), subTitle: query.trim(), limit: -1 },
      }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setTestimonialsState({ testimonials: resp.data.results, refresh })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTestimonialLoading(false));
  }
};

/**
 * To testimonial from server
 */
export const getTestimonialById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeTestimonialLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.testimonialEn + "/" + id
        : ApiEndpoints.testimonialAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setTestimonialById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTestimonialLoading(false));
  }
};

/**
 * To add a new  testimonial
 */
export const addTestimonial = async (testimonialData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeTestimonialLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH"
        ? ApiEndpoints.testimonialEn
        : ApiEndpoints.testimonialAr,
      testimonialData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }
    {
      console.log(resp);
    }
    // Update to Store
    dispatch(addTestimonialState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTestimonialLoading(false));
  }
};

/**
 * To edit a new  testimonial
 */
export const editTestimonial = async (
  id,
  testimonialData,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeTestimonialLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.testimonialEn + "/" + id
        : ApiEndpoints.testimonialAr + "/" + id,
      testimonialData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editTestimonialState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTestimonialLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeTestimonialVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeTestimonialLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.testimonialVisibilityEn + id
        : ApiEndpoints.testimonialVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeTestimonialVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTestimonialLoading(false));
  }
};

/**
 * To delete the testimonial
 */
export const deleteTestimonial = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeTestimonialLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.testimonialEn + "/" + id
        : ApiEndpoints.testimonialAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteTestimonialState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTestimonialLoading(false));
  }
};
