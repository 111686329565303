import "../../styles/error.scss";

const ScreenError = () => {
  return (
    <div className="errorcontainer">
      <div class="container">
        <h1>404</h1>
        <h1>Oops!</h1>
        <p>Something went wrong.</p>
      </div>
    </div>
  );
};

export default ScreenError;
