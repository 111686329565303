import React, { useState } from "react";
import { BsJustify } from "react-icons/bs";
import { IoLanguage } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  clearAdminState,
  setAdminLogout,
  switchLanguage,
} from "../redux/adminReducer";
import { clearAwardState } from "../redux/awardReducer";
import { clearAwardBodyState } from "../redux/awardBodyReducer";
import { clearCareerState } from "../redux/careerReducer";
import { clearClientState } from "../redux/clientReducer";
import { clearIndustryState } from "../redux/industryReducer";
import { clearInsightNewsState } from "../redux/insightNewsReducer";
import { clearInsightVideoState } from "../redux/insightVideoReducer";
import { clearPartnerState } from "../redux/partnerReducer";
import { clearServiceState } from "../redux/serviceReducer";
import { clearSolutionState } from "../redux/solutionReducer";
import { clearSolutionBodyState } from "../redux/solutionBodyReducer";
import { clearTestimonialState } from "../redux/testimonialReducer";
import { useSelector } from "react-redux";
import { changeAdminPassword } from "../services/adminServices";

const Header = ({ OpenSidebar }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { language, profile } = useSelector((state) => state.admin);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleLogout = () => {
    dispatch(setAdminLogout());
    dispatch(clearAdminState());
  };

  const handleChangePassword = () => {
    setIsChangePasswordOpen(true);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const clearLanguageStates = () => {
    dispatch(clearAwardState());
    dispatch(clearAwardBodyState());
    dispatch(clearCareerState());
    dispatch(clearClientState());
    dispatch(clearIndustryState());
    dispatch(clearInsightNewsState());
    dispatch(clearInsightVideoState());
    dispatch(clearPartnerState());
    dispatch(clearServiceState());
    dispatch(clearSolutionState());
    dispatch(clearSolutionBodyState());
    dispatch(clearTestimonialState());
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleLanguageSelection = () => {
    clearLanguageStates();
    setIsDropdownOpen(false);
    dispatch(switchLanguage(language === "ENGLISH" ? "ARABIC" : "ENGLISH"));
    window.location.reload();
  };

  const handleChangePasswordSubmit = async () => {
    // if (currentPassword === newPassword || newPassword !== confirmPassword) {
    //   return;
    // }
    const resp = await changeAdminPassword({
      currentPassword,
      newPassword,
    });
    if (resp) {
      setIsChangePasswordOpen(false);
    }
  };

  return (
    <header className="headernormal flex items-center justify-between">
      <div className="menu-iconnormal">
        <BsJustify className="iconnormal" onClick={OpenSidebar} />
      </div>

      <div className="header-rightnormal flex items-center">
        {/* Language Switching Button */}
        <h1 className="text-red-500 font-extrabold mx-2">
          {language === "ENGLISH" ? "English Admin" : "Arabic Admin"}
        </h1>

        <div className="relative mr-4">
          <IoLanguage
            id="languageButton"
            className={`w-10 h-10 cursor-pointer`}
            onClick={toggleLanguageSelection}
          />
        </div>

        {isChangePasswordOpen && (
          <div class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-modal md:h-full">
            <div class="relative p-4 w-full max-w-md h-full md:h-auto">
              <div class="relative p-6 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-8">
                <button
                  type="button"
                  class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsChangePasswordOpen(false)}
                >
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div class="w-full bg-white rounded-lg md:mt-0 sm:max-w-md">
                  <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Change Password
                  </h2>
                  <form class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                    <div>
                      <label
                        for="current-password"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Current Password
                      </label>
                      <input
                        type="password"
                        name="current-password"
                        id="current-password"
                        placeholder="Current Password"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required={true}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="New Password"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required={true}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        for="confirm-password"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Confirm password
                      </label>
                      <input
                        type="password"
                        name="confirm-password"
                        id="confirm-password"
                        placeholder="Confirm Password"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required={true}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <button
                      type="button"
                      class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      onClick={handleChangePasswordSubmit}
                    >
                      Change passwod
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* User Avatar Dropdown */}
        <div className="relative">
          <img
            id="avatarButton"
            type="button"
            onClick={toggleDropdown}
            className="w-10 h-10 rounded-full cursor-pointer"
            src="/avatar.png"
            alt="User dropdown"
          />

          {isDropdownOpen && (
            <div
              id="userDropdown"
              className="absolute right-0 mt-4 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
            >
              {profile && (
                <>
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="avatarButton"
                  >
                    <li className="cursor-default block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                      Name: {profile.name}
                    </li>
                  </ul>
                  <div className="py-1">
                    <a
                      onClick={handleChangePassword}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Change Password
                    </a>
                  </div>
                </>
              )}

              <div className="py-1">
                <a
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  {" "}
                  {language === "ENGLISH" ? "Sign out" : "تسجيل الخروج"}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
