import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeClientLoading,
  setClientsState,
  setClientById,
  addClientState,
  editClientState,
  changeClientVisibilityState,
  deleteClientState,
} from "../redux/clientReducer";

/**
 * To get a  clients from server
 */
export const getClients = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeClientLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH" ? ApiEndpoints.clientEn : ApiEndpoints.clientAr,
      {
        params: {
          name: query.trim(),
          limit: -1,
        },
      }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    console.log(resp);

    // Update to Store
    dispatch(setClientsState({ clients: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeClientLoading(false));
  }
};

/**
 * To client from server
 */
export const getClientById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeClientLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.clientEn + "/" + id
        : ApiEndpoints.clientAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setClientById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeClientLoading(false));
  }
};

/**
 * To add a new  client
 */
export const addClient = async (clientData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeClientLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH" ? ApiEndpoints.clientEn : ApiEndpoints.clientAr,
      clientData
    );
    console.log("Response:", resp);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addClientState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeClientLoading(false));
  }
};

/**
 * To edit a new  client
 */
export const editClient = async (id, clientData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeClientLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.clientEn + "/" + id
        : ApiEndpoints.clientAr + "/" + id,
      clientData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editClientState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeClientLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeClientVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeClientLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.clientVisibilityEn + id
        : ApiEndpoints.clientVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeClientVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeClientLoading(false));
  }
};

/**
 * To delete the client
 */
export const deleteClient = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeClientLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.clientEn + "/" + id
        : ApiEndpoints.clientAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteClientState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeClientLoading(false));
  }
};
