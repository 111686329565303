import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeSolutionLoading,
  setSolutionsState,
  setSolutionById,
  addSolutionState,
  editSolutionState,
  changeSolutionVisibilityState,
  deleteSolutionState,
} from "../redux/solutionReducer";

/**
 * To get a  solutions from server
 */
export const getSolutions = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeSolutionLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.solutionEn
        : ApiEndpoints.solutionAr,
      {
        params: { title: query.trim(), limit: -1 },
      }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSolutionsState({ solutions: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionLoading(false));
  }
};

/**
 * To solution from server
 */
export const getSolutionById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeSolutionLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.solutionEn + "/" + id
        : ApiEndpoints.solutionAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSolutionById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionLoading(false));
  }
};

/**
 * To add a new  solution
 */
export const addSolution = async (solutionData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeSolutionLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH"
        ? ApiEndpoints.solutionEn
        : ApiEndpoints.solutionAr,
      solutionData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addSolutionState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionLoading(false));
  }
};

/**
 * To edit a new  solution
 */
export const editSolution = async (id, solutionData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeSolutionLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.solutionEn + "/" + id
        : ApiEndpoints.solutionAr + "/" + id,
      solutionData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editSolutionState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeSolutionVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeSolutionLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.solutionVisibilityEn + id
        : ApiEndpoints.solutionVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeSolutionVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionLoading(false));
  }
};

/**
 * To delete the solution
 */
export const deleteSolution = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeSolutionLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.solutionEn + "/" + id
        : ApiEndpoints.solutionAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteSolutionState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeSolutionLoading(false));
  }
};
