import { Navigate } from "react-router-dom";
import { KRoutes } from "./routers";
import LocalStorageKeys from "../core/localstorageKeys";

const PrivateRoute = ({ component, ...rest }) => {
  const adminToken = localStorage.getItem(LocalStorageKeys.adminToken);
  // Get a lanuage 
  return !adminToken ? <Navigate to={KRoutes.login} /> : component;
};

export default PrivateRoute;
