import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeAdminLoading,
  setAdminsState,
  setAdminLoggedIn,
  setAdminLogout,
  setAdminProfile,
  addAdminState,
  editAdminState,
  changeAdminStatusState,
  deleteAdminState,
  setAdminById,
} from "../redux/adminReducer";

/**
 * To get a  admins from server
 */
export const getAdmins = async (refresh = false, query = "") => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.admin, {
      params: {
        name: query.trim(),
        email: query.trim(),
        limit: -1,
      },
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminsState({ admins: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To admin from server
 */
export const getAdminById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.admin + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To admin from server
 */
export const getAdminProfile = async () => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.adminProfile);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminProfile(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To login a admin from server api
 */
export const adminLogin = async (data,csrfToken) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient("application/json",csrfToken).patch(ApiEndpoints.adminLogin, data);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminLoggedIn(resp.data.results.token));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To add a new  admin
 */
export const addAdmin = async (adminData) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.admin, adminData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addAdminState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To edit a new  admin
 */
export const editAdmin = async (id, adminData) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.admin + "/" + id,
      adminData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editAdminState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To change the password
 */
export const changeAdminPassword = async (body) => {
  try {
    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.changeAdminPassword,
      body
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To change the status
 */
export const changeAdminStatus = async (id, status) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.adminStatus + id, {
      status,
    });
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeAdminStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To delete the admin
 */
export const deleteAdmin = async (id) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.admin + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteAdminState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To delete the admin permanently
 */
export const logoutAdmin = async () => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // Update to Store
    dispatch(setAdminLogout());
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};
