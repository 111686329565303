import { createSlice } from "@reduxjs/toolkit";

const awardInitState = {
  isLoading: false,
  awards: [],
  award: {},
};

const awardSlice = createSlice({
  name: "award",
  initialState: awardInitState,
  reducers: {
    changeAwardLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setAwardById: (state, action) => {
      state.award = action.payload;
      state.isLoading = false;
    },
    setAwardsState: (state, action) => {
      if (state.awards.length <= 0 || action.payload.refresh) {
        state.awards = action.payload.awards || state.awards;
      }
      state.isLoading = false;
    },
    addAwardState: (state, action) => {
      state.awards.unshift(action.payload);
      state.isLoading = false;
    },
    editAwardState: (state, action) => {
      state.awards = state.awards.map((award) => {
        if (action.payload.id === award.id) {
          award = action.payload;
        }
        return award;
      });
      state.isLoading = false;
    },
    changeAwardVisibilityState: (state, action) => {
      state.awards = state.awards.map((award) => {
        if (
          action.payload.id === award.id &&
          action.payload.visibility !== award.visibility
        ) {
          award.visibility = action.payload.visibility;
        }
        return award;
      });
      state.isLoading = false;
    },
    deleteAwardState: (state, action) => {
      state.awards = state.awards.filter(
        (award) => award.id !== action.payload
      );
      state.isLoading = false;
    },
    clearAwardState: (state) => {
      state = awardInitState;
    },
  },
});

export const {
  changeAwardLoading,
  setAwardsState,
  setAwardById,
  addAwardState,
  editAwardState,
  changeAwardVisibilityState,
  deleteAwardState,
  clearAwardState,
} = awardSlice.actions;

export default awardSlice.reducer;
