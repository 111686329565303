import { createSlice } from "@reduxjs/toolkit";

const industryInitState = {
  isLoading: false,
  industries: [],
  industry: {},
};

const industrySlice = createSlice({
  name: "industry",
  initialState: industryInitState,
  reducers: {
    changeIndustryLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setIndustryById: (state, action) => {
      state.industry = action.payload;
      state.isLoading = false;
    },
    setIndustriesState: (state, action) => {
      if (state.industries.length <= 0 || action.payload.refresh) {
        state.industries = action.payload.industries || state.industries;
      }
      state.isLoading = false;
    },
    addIndustryState: (state, action) => {
      state.industries.unshift(action.payload);
      state.isLoading = false;
    },
    editIndustryState: (state, action) => {
      state.industries = state.industries.map((industry) => {
        if (action.payload.id === industry.id) {
          industry = action.payload;
        }
        return industry;
      });
      state.isLoading = false;
    },
    changeIndustryVisibilityState: (state, action) => {
      state.industries = state.industries.map((industry) => {
        if (
          action.payload.id === industry.id &&
          action.payload.visibility !== industry.visibility
        ) {
          industry.visibility = action.payload.visibility;
        }
        return industry;
      });
      state.isLoading = false;
    },
    deleteIndustryState: (state, action) => {
      state.industries = state.industries.filter(
        (industry) => industry.id !== action.payload
      );
      state.isLoading = false;
    },
    clearIndustryState: (state) => {
      state = industryInitState;
    },
  },
});

export const {
  changeIndustryLoading,
  setIndustriesState,
  setIndustryById,
  addIndustryState,
  editIndustryState,
  changeIndustryVisibilityState,
  deleteIndustryState,
  clearIndustryState,
} = industrySlice.actions;

export default industrySlice.reducer;
