import React, { useEffect, useState } from "react";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import { addSolution, editSolution } from "../../../services/solutionServices";
import { getSolutionById } from "../../../services/solutionServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddSolution = () => {
  const navigate = useNavigate();
  const { language } = useSelector((state) => state.admin);
  const { solutionId } = useParams();

  const [title, setTitle] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerDescription, setHeaderDescription] = useState("");
  const [footerTitle, setFooterTitle] = useState("");
  const [footerDescription, setFooterDescription] = useState("");
  const [solType, setSolType] = useState("ICT");
  const [selectedImage, setSelectedImage] = useState();
  const [solutionImage, setSolutionImage] = useState(null);

  useEffect(() => {
    const getSolution = async () => {
      if (solutionId) {
        const solution = await getSolutionById(solutionId, language);
        setTitle(solution.title);
        setHeaderTitle(solution.headerTitle);
        setHeaderDescription(solution.headerDescription);
        setFooterTitle(solution.footerTitle);
        setFooterDescription(solution.footerDescription);
        setSolType(solution.type);
        setSolutionImage(ApiEndpoints.imageURL + solution.image);
      }
    };
    getSolution();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setSolutionImage(null);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("type", solType);
      formData.append("headerTitle", headerTitle);
      formData.append("headerDescription", headerDescription);
      formData.append("footerTitle", footerTitle);
      formData.append("footerDescription", footerDescription);
      formData.append("image", selectedImage);

      let resp = false;
      if (solutionId) {
        resp = await editSolution(solutionId, formData, language);
      } else {
        resp = await addSolution(formData, language);
      }
      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };
  const handleClick = () => {
    navigate(KRoutes.solutions);
  };

  return (
    <Layout title="solutions">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / {solutionId ? "Edit" : "Add"} Solutions</h1>
        </div>

        <div className=" bg-white-500  flex items-start justify-start">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Solutions Details</h2>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <label htmlFor="CareerTitle" className="block mb-2">
                Title :
              </label>
              <input
                type="text"
                id="CareerTitle"
                value={title}
                style={{ marginLeft: "1em", marginRight: "1em" }}
                onChange={(e) => setTitle(e.target.value)}
                className="border border-gray-400 p-2 mb-4"
              />

              <label htmlFor="SolutionType" className="block mb-2">
                Type :
              </label>
              <select
                id="SolutionType"
                style={{ marginLeft: "1em" }}
                value={solType}
                onChange={(e) => setSolType(e.target.value)}
                className="border border-gray-400 p-2 mb-4"
              >
                <option value="ICT">ICT</option>
                <option value="DIGITAL_TRANSFORMATION">
                  DIGITAL TRANSFORMATION
                </option>
                <option value="ELV">ELV</option>
                <option value="AUDIO_VISUAL">AUDIO VISUAL</option>
                <option value="DATA_CENTRE">DATA CENTRE</option>
                <option value="APC_TURNKEY_PROJECTS">
                  APC TURNKEY PROJECTS
                </option>
              </select>
            </div>

            <label htmlFor="headerTitle" className="block mb-2">
              Header Title :
            </label>
            <input
              type="text"
              id="HeaderTitle"
              style={{ width: "100%" }}
              value={headerTitle}
              onChange={(e) => setHeaderTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="headerDescription" className="block mb-2">
              Header Description:
            </label>
            <textarea
              id="testimonialDescription"
              style={{ width: "100%" }}
              value={headerDescription}
              onChange={(e) => setHeaderDescription(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="footerTitle" className="block mb-2">
              Footer Title :
            </label>
            <input
              type="text"
              id="FooterTitle"
              style={{ width: "100%" }}
              value={footerTitle}
              onChange={(e) => setFooterTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="footerDescription" className="block mb-2">
              Footer Description:
            </label>
            <textarea
              id="footerDescription"
              value={footerDescription}
              style={{ width: "100%" }}
              onChange={(e) => setFooterDescription(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />

            <input type="file" onChange={handleImageChange} />
            {(selectedImage || solutionImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : solutionImage
                }
                alt="Selected Partner"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddSolution;
