import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeContactLoading,
  setContactsState,
  setContactById,
  addContactState,
  editContactState,
  deleteContactState,
} from "../redux/contactReducer";

/**
 * To get a  contacts from server
 */
export const getContacts = async (refresh = false, query = "") => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeContactLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.contact, {
      params: { name: query.trim(), email: query.trim(), limit: -1 },
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setContactsState({ contacts: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeContactLoading(false));
  }
};

/**
 * To contact from server
 */
export const getContactById = async (id) => {
  try {
    // Set Loading
    dispatch(changeContactLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.contact + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setContactById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeContactLoading(false));
  }
};

/**
 * To add a new  contact
 */
export const addContact = async (contactData) => {
  try {
    // Set Loading
    dispatch(changeContactLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.contact, contactData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addContactState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeContactLoading(false));
  }
};

/**
 * To edit a new  contact
 */
export const editContact = async (id, contactData) => {
  try {
    // Set Loading
    dispatch(changeContactLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.contact + "/" + id,
      contactData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editContactState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeContactLoading(false));
  }
};

/**
 * To delete the contact
 */
export const deleteContact = async (id) => {
  try {
    // Set Loading
    dispatch(changeContactLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.contact + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteContactState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeContactLoading(false));
  }
};
