import { createSlice } from "@reduxjs/toolkit";

const solutionBodyInitState = {
  isLoading: false,
  solutionBodies: [],
  solutionBody: {},
};

const solutionBodySlice = createSlice({
  name: "solution-body",
  initialState: solutionBodyInitState,
  reducers: {
    changeSolutionBodyLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setSolutionBodyById: (state, action) => {
      state.solutionBody = action.payload;
      state.isLoading = false;
    },
    setSolutionBodiesState: (state, action) => {
      if (state.solutionBodies.length <= 0 || action.payload.refresh) {
        state.solutionBodies =
          action.payload.solutionBodies || state.solutionBodies;
      }
      state.isLoading = false;
    },
    addSolutionBodyState: (state, action) => {
      state.solutionBodies.unshift(action.payload);
      state.isLoading = false;
    },
    editSolutionBodyState: (state, action) => {
      state.solutionBodies = state.solutionBodies.map((solutionBody) => {
        if (action.payload.id === solutionBody.id) {
          solutionBody = action.payload;
        }
        return solutionBody;
      });
      state.isLoading = false;
    },
    changeSolutionBodyVisibilityState: (state, action) => {
      state.solutionBodies = state.solutionBodies.map((solutionBody) => {
        if (
          action.payload.id === solutionBody.id &&
          action.payload.visibility !== solutionBody.visibility
        ) {
          solutionBody.visibility = action.payload.visibility;
        }
        return solutionBody;
      });
      state.isLoading = false;
    },
    deleteSolutionBodyState: (state, action) => {
      state.solutionBodies = state.solutionBodies.filter(
        (solutionBody) => solutionBody.id !== action.payload
      );
      state.isLoading = false;
    },
    clearSolutionBodyState: (state) => {
      console.log("Solution body cleared");
      state = solutionBodyInitState;
    },
  },
});

export const {
  changeSolutionBodyLoading,
  setSolutionBodiesState,
  setSolutionBodyById,
  addSolutionBodyState,
  editSolutionBodyState,
  changeSolutionBodyVisibilityState,
  deleteSolutionBodyState,
  clearSolutionBodyState,
} = solutionBodySlice.actions;

export default solutionBodySlice.reducer;
