import { createSlice } from "@reduxjs/toolkit";

const insightNewsInitState = {
  isLoading: false,
  insightNews: [],
  singleInsightNews: {},
};

const insightNewsSlice = createSlice({
  name: "insightNews",
  initialState: insightNewsInitState,
  reducers: {
    changeInsightNewsLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setInsightNewsById: (state, action) => {
      state.singleInsightNews = action.payload;
      state.isLoading = false;
    },
    setInsightNewsState: (state, action) => {
      if (state.insightNews.length <= 0 || action.payload.refresh) {
        state.insightNews = action.payload.insightNews || state.insightNews;
      }
      state.isLoading = false;
    },
    addInsightNewsState: (state, action) => {
      state.insightNews.unshift(action.payload);
      state.isLoading = false;
    },
    editInsightNewsState: (state, action) => {
      state.insightNews = state.insightNews.map((insightNews) => {
        if (action.payload.id === insightNews.id) {
          insightNews = action.payload;
        }
        return insightNews;
      });
      state.isLoading = false;
    },
    changeInsightNewsChangeTopPriorityState: (state, action) => {
      state.insightNews = state.insightNews.map((insightNews) => {
        if (
          action.payload.id === insightNews.id &&
          action.payload.topNewsEnabled !== insightNews.topNewsEnabled
        ) {
          insightNews.topNewsEnabled = action.payload.topNewsEnabled;
        }
        return insightNews;
      });
      state.isLoading = false;
    },
    changeInsightNewsVisibilityState: (state, action) => {
      state.insightNews = state.insightNews.map((insightNews) => {
        if (
          action.payload.id === insightNews.id &&
          action.payload.visibility !== insightNews.visibility
        ) {
          insightNews.visibility = action.payload.visibility;
        }
        return insightNews;
      });
      state.isLoading = false;
    },
    deleteInsightNewsState: (state, action) => {
      state.insightNews = state.insightNews.filter(
        (insightNews) => insightNews.id !== action.payload
      );
      state.isLoading = false;
    },
    clearInsightNewsState: (state) => {
      state = insightNewsInitState;
    },
  },
});

export const {
  changeInsightNewsLoading,
  setInsightNewsState,
  setInsightNewsById,
  addInsightNewsState,
  editInsightNewsState,
  changeInsightNewsChangeTopPriorityState,
  changeInsightNewsVisibilityState,
  deleteInsightNewsState,
  clearInsightNewsState,
} = insightNewsSlice.actions;

export default insightNewsSlice.reducer;
