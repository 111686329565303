import { createSlice } from "@reduxjs/toolkit";

const partnerInitState = {
  isLoading: false,
  partners: [],
  partner: {},
};

const partnerSlice = createSlice({
  name: "partner",
  initialState: partnerInitState,
  reducers: {
    changePartnerLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setPartnerById: (state, action) => {
      state.partner = action.payload;
      state.isLoading = false;
    },
    setPartnersState: (state, action) => {
      if (state.partners.length <= 0 || action.payload.refresh) {
        state.partners = action.payload.partners || state.partners;
      }
      state.isLoading = false;
    },
    addPartnerState: (state, action) => {
      state.partners.unshift(action.payload);
      state.isLoading = false;
    },
    editPartnerState: (state, action) => {
      state.partners = state.partners.map((partner) => {
        if (action.payload.id === partner.id) {
          partner = action.payload;
        }
        return partner;
      });
      state.isLoading = false;
    },
    changePartnerVisibilityState: (state, action) => {
      state.partners = state.partners.map((partner) => {
        if (
          action.payload.id === partner.id &&
          action.payload.visibility !== partner.visibility
        ) {
          partner.visibility = action.payload.visibility;
        }
        return partner;
      });
      state.isLoading = false;
    },
    deletePartnerState: (state, action) => {
      console.log(action.payload);
      state.partners = state.partners.filter(
        (partner) => partner.id !== action.payload
      );
      state.isLoading = false;
    },
    clearPartnerState: (state) => {
      state = partnerInitState;
    },
  },
});

export const {
  changePartnerLoading,
  setPartnersState,
  setPartnerById,
  addPartnerState,
  editPartnerState,
  changePartnerVisibilityState,
  deletePartnerState,
  clearPartnerState,
} = partnerSlice.actions;

export default partnerSlice.reducer;
