import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeInsightVideoLoading,
  setInsightVideosState,
  setInsightVideoById,
  addInsightVideoState,
  editInsightVideoState,
  changeInsightVideoVisibilityState,
  deleteInsightVideoState,
} from "../redux/insightVideoReducer";

/**
 * To get a  insightVideos from server
 */
export const getInsightVideos = async (
  language = "ENGLISH",
  refresh = false
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeInsightVideoLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.insightVideoEn
        : ApiEndpoints.insightVideoAr,
      { params: { limit: -1 } }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setInsightVideosState({ insightVideos: resp.data.results, refresh })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightVideoLoading(false));
  }
};

/**
 * To insightVideo from server
 */
export const getInsightVideoById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeInsightVideoLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.insightVideoEn + "/" + id
        : ApiEndpoints.insightVideoAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setInsightVideoById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightVideoLoading(false));
  }
};

/**
 * To add a new  insightVideo
 */
export const addInsightVideo = async (
  insightVideoData,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeInsightVideoLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH"
        ? ApiEndpoints.insightVideoEn
        : ApiEndpoints.insightVideoAr,
      insightVideoData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addInsightVideoState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightVideoLoading(false));
  }
};

/**
 * To edit a new  insightVideo
 */
export const editInsightVideo = async (
  id,
  insightVideoData,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeInsightVideoLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.insightVideoEn + "/" + id
        : ApiEndpoints.insightVideoAr + "/" + id,
      insightVideoData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editInsightVideoState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightVideoLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeInsightVideoVisibility = async (
  id,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeInsightVideoLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.insightVideoVisibilityEn + id
        : ApiEndpoints.insightVideoVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeInsightVideoVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightVideoLoading(false));
  }
};

/**
 * To delete the insightVideo
 */
export const deleteInsightVideo = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeInsightVideoLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.insightVideoEn + "/" + id
        : ApiEndpoints.insightVideoAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteInsightVideoState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightVideoLoading(false));
  }
};
