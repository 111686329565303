import { createSlice } from "@reduxjs/toolkit";

const subscriberInitState = {
  isLoading: false,
  subscribers: [],
  subscriber: {},
};

const subscriberSlice = createSlice({
  name: "subscriber",
  initialState: subscriberInitState,
  reducers: {
    changeSubscriberLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setSubscriberById: (state, action) => {
      state.subscriber = action.payload;
      state.isLoading = false;
    },
    setSubscribersState: (state, action) => {
      if (state.subscribers.length <= 0 || action.payload.refresh) {
        state.subscribers = action.payload.subscribers || state.subscribers;
      }
      state.isLoading = false;
    },
    addSubscriberState: (state, action) => {
      state.subscribers.unshift(action.payload);
      state.isLoading = false;
    },
    editSubscriberState: (state, action) => {
      state.subscribers = state.subscribers.map((subscriber) => {
        if (action.payload.id === subscriber.id) {
          subscriber = action.payload;
        }
        return subscriber;
      });
      state.isLoading = false;
    },
    changeSubscriberVisibilityState: (state, action) => {
      state.subscribers = state.subscribers.map((subscriber) => {
        if (
          action.payload.id === subscriber.id &&
          action.payload.visibility !== subscriber.visibility
        ) {
          subscriber.visibility = action.payload.visibility;
        }
        return subscriber;
      });
      state.isLoading = false;
    },
    deleteSubscriberState: (state, action) => {
      state.subscribers = state.subscribers.filter(
        (subscriber) => subscriber.id !== action.payload
      );
      state.isLoading = false;
    },
    clearSubscriberState: (state) => {
      state = subscriberInitState;
    },
  },
});

export const {
  changeSubscriberLoading,
  setSubscribersState,
  setSubscriberById,
  addSubscriberState,
  editSubscriberState,
  changeSubscriberVisibilityState,
  deleteSubscriberState,
  clearSubscriberState,
} = subscriberSlice.actions;

export default subscriberSlice.reducer;
