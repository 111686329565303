import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeInsightNewsLoading,
  setInsightNewsState,
  setInsightNewsById,
  addInsightNewsState,
  editInsightNewsState,
  changeInsightNewsVisibilityState,
  deleteInsightNewsState,
  changeInsightNewsChangeTopPriorityState,
} from "../redux/insightNewsReducer";

/**
 * To get a  insightNews from server
 */
export const getInsightNews = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeInsightNewsLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.insightNewsEn
        : ApiEndpoints.insightNewsAr,
      { params: { title: query.trim(), limit: -1 } }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setInsightNewsState({ insightNews: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightNewsLoading(false));
  }
};

/**
 * To insightNews from server
 */
export const getInsightNewsById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeInsightNewsLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.insightNewsEn + "/" + id
        : ApiEndpoints.insightNewsAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setInsightNewsById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightNewsLoading(false));
  }
};

/**
 * To add a new  insightNews
 */
export const addInsightNews = async (insightNewsData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeInsightNewsLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH"
        ? ApiEndpoints.insightNewsEn
        : ApiEndpoints.insightNewsAr,
      insightNewsData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addInsightNewsState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightNewsLoading(false));
  }
};

/**
 * To edit a new  insightNews
 */
export const editInsightNews = async (
  id,
  insightNewsData,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeInsightNewsLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.insightNewsEn + "/" + id
        : ApiEndpoints.insightNewsAr + "/" + id,
      insightNewsData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editInsightNewsState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightNewsLoading(false));
  }
};




/**
 * To change the top priority
 */
export const changeInsightNewChangeTopPriority = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeInsightNewsLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.insightNewsChangeTopPriorityEn + id
        : ApiEndpoints.insightNewsChangeTopPriorityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeInsightNewsChangeTopPriorityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightNewsLoading(false));
  }
};









/**
 * To change the visbility
 */
export const changeInsightNewsVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeInsightNewsLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.insightNewsVisibilityEn + id
        : ApiEndpoints.insightNewsVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeInsightNewsVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightNewsLoading(false));
  }
};

/**
 * To delete the insightNews
 */
export const deleteInsightNews = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeInsightNewsLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.insightNewsEn + "/" + id
        : ApiEndpoints.insightNewsAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteInsightNewsState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeInsightNewsLoading(false));
  }
};
