import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeServiceLoading,
  setServicesState,
  setServiceById,
  addServiceState,
  editServiceState,
  changeServiceVisibilityState,
  deleteServiceState,
} from "../redux/serviceReducer";

/**
 * To get a  services from server
 */
export const getServices = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH" ? ApiEndpoints.serviceEn : ApiEndpoints.serviceAr,
      { params: { title: query.trim(), limit: -1 } }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServicesState({ services: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To service from server
 */
export const getServiceById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.serviceEn + "/" + id
        : ApiEndpoints.serviceAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setServiceById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To add a new  service
 */
export const addService = async (serviceData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH" ? ApiEndpoints.serviceEn : ApiEndpoints.serviceAr,
      serviceData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addServiceState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To edit a new  service
 */
export const editService = async (id, serviceData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.serviceEn + "/" + id
        : ApiEndpoints.serviceAr + "/" + id,
      serviceData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editServiceState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeServiceVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.serviceVisibilityEn + id
        : ApiEndpoints.serviceVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeServiceVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};

/**
 * To delete the service
 */
export const deleteService = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeServiceLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.serviceEn + "/" + id
        : ApiEndpoints.serviceAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteServiceState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeServiceLoading(false));
  }
};
