import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "./notificationReducer.js";
import adminReducer from "./adminReducer.js";
import awardReducer from "./awardReducer.js";
import awardBodyReducer from "./awardBodyReducer.js";
import careerReducer from "./careerReducer.js";
import clientReducer from "./clientReducer.js";
import contactReducer from "./contactReducer.js";
import industryReducer from "./industryReducer.js";
import insightNewsReducer from "./insightNewsReducer.js";
import insightVideosReducer from "./insightVideoReducer.js";
import partnerReducer from "./partnerReducer.js";
import serviceReducer from "./serviceReducer.js";
import solutionReducer from "./solutionReducer.js";
import solutionBodyReducer from "./solutionBodyReducer.js";
import subscriberReducer from "./subscriberReducer.js";
import testimonialReducer from "./testimonialReducer.js";

const store = configureStore({
  reducer: {
    notification: notificationReducer,
    admin: adminReducer,
    award: awardReducer,
    awardBody: awardBodyReducer,
    careers: careerReducer,
    client: clientReducer,
    contact: contactReducer,
    industry: industryReducer,
    insightNews: insightNewsReducer,
    insightVideo: insightVideosReducer,
    partner: partnerReducer,
    service: serviceReducer,
    solution: solutionReducer,
    solutionBody: solutionBodyReducer,
    subscriber: subscriberReducer,
    testimonial: testimonialReducer,
  },
});

export const dispatch = store.dispatch;
export default store;
