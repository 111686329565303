class ApiEndpoints {
  static serverURL =
    process.env.REACT_APP_SERVER_URL || "https://backend.rts.ae";
  static baseURL = `${this.serverURL}/api/v1`;
  static imageURL = `${this.serverURL}/image/`;
  static SITEKEY =
    process.env.REACT_APP_SITEKEY || "6Lep14EpAAAAAONxOsmVt5Eing24uUl8I6vSga-B";

  //Admin
  static admin = "/admin";
  static adminProfile = `${this.admin}/profile`;
  static adminLogin = `${this.admin}/login`;
  static adminStatus = `${this.admin}/change-status/`;
  static changeAdminPassword = `${this.admin}/change-password/`;

  //Awards English
  static awardEn = "/en/award";
  static awardVisibilityEn = `${this.awardEn}/change-visibility/`;

  // Award Arabic
  static awardAr = "/ar/award";
  static awardVisibilityAr = `${this.awardAr}/change-visibility/`;

  //Awards Body Arabic
  static awardBodyAr = "/ar/award-body";
  static awardBodysAr = "/ar/award-body/award/";
  static awardBodyVisibilityAr = `${this.awardBodyAr}/change-visibility/`;

  //Awards Body English
  static awardBodyEn = "/en/award-body";
  static awardBodysEn = "/en/award-body/award/";
  static awardBodyVisibilityEn = `${this.awardBodyEn}/change-visibility/`;

  // Career Arabic
  static careerAr = "/ar/career";
  static careerVisibilityAr = `${this.careerAr}/change-visibility/`;

  // Career English
  static careerEn = "/en/career";
  static careerVisibilityEn = `${this.careerEn}/change-visibility/`;

  //Client Arabic
  static clientAr = "/ar/client";
  static clientVisibilityAr = `${this.clientAr}/change-visibility/`;

  //Client English
  static clientEn = "/en/client";
  static clientVisibilityEn = `${this.clientEn}/change-visibility/`;

  //Contact
  static contact = "/contact";

  //Industry Arabic
  static industryEn = "/en/industry";
  static industryVisibilityEn = `${this.industryEn}/change-visibility/`;

  //Industry English
  static industryAr = "/ar/industry";
  static industryVisibilityAr = `${this.industryAr}/change-visibility/`;

  //Insight New Arabic
  static insightNewsAr = "/ar/insight-news";
  static insightNewsChangeTopPriorityAr = `${this.insightNewsAr}/change-top-priority/`;
  static insightNewsVisibilityAr = `${this.insightNewsAr}/change-visibility/`;

  //Insight New English
  static insightNewsEn = "/en/insight-news";
  static insightNewsChangeTopPriorityEn = `${this.insightNewsEn}/change-top-priority/`;
  static insightNewsVisibilityEn = `${this.insightNewsEn}/change-visibility/`;

  //Insight Video Arabic
  static insightVideoAr = "/ar/insight-video";
  static insightVideoVisibilityAr = `${this.insightVideoAr}/change-visibility/`;

  //Insight Video English
  static insightVideoEn = "/en/insight-video";
  static insightVideoVisibilityEn = `${this.insightVideoEn}/change-visibility/`;

  // Partner English
  static partnerEn = "/en/partner";
  static partnerVisibilityEn = `${this.partnerEn}/change-visibility/`;

  // Partner Arabic
  static partnerAr = "/ar/partner";
  static partnerVisibilityAr = `${this.partnerAr}/change-visibility/`;

  // Service English
  static serviceEn = "/en/service";
  static serviceVisibilityEn = `${this.serviceEn}/change-visibility/`;

  // Service Arabic
  static serviceAr = "/ar/service";
  static serviceVisibilityAr = `${this.serviceAr}/change-visibility/`;

  // Solution English
  static solutionEn = "/en/solution";
  static solutionVisibilityEn = `${this.solutionEn}/change-visibility/`;

  // Solution Arabic
  static solutionAr = "/ar/solution";
  static solutionVisibilityAr = `${this.solutionAr}/change-visibility/`;

  // Solution Body English
  static solutionBodyEn = "/en/solution-body";
  static solutionBodysEn = "/en/solution-body/solution/";
  static solutionBodyVisibilityEn = `${this.solutionBodyEn}/change-visibility/`;

  // Solution Body Arabic
  static solutionBodyAr = "/ar/solution-body";
  static solutionBodysAr = "/ar/solution-body/solution/";
  static solutionBodyVisibilityAr = `${this.solutionBodyAr}/change-visibility/`;

  // Subscriber
  static subscriber = "/subscriber";
  static subscriberVisibility = `${this.subscriber}/change-visibility/`;

  // Testimonial English
  static testimonialEn = "/en/testimonial";
  static testimonialVisibilityEn = `${this.testimonialEn}/change-visibility/`;

  // Testimonial Arabic
  static testimonialAr = "/ar/testimonial";
  static testimonialVisibilityAr = `${this.testimonialAr}/change-visibility/`;
}
export default ApiEndpoints;
