import httpCareer from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeCareerLoading,
  setCareersState,
  setCareerById,
  addCareerState,
  editCareerState,
  changeCareerVisibilityState,
  deleteCareerState,
} from "../redux/careerReducer";

/**
 * To get a  careers from server
 */
export const getCareers = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeCareerLoading(true));

    // API Fetching
    const resp = await httpCareer().get(
      language === "ENGLISH" ? ApiEndpoints.careerEn : ApiEndpoints.careerAr,
      {
        params: {
          title: query.trim(),
          limit: -1,
        },
      }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setCareersState({ careers: resp.data.results, refresh }));

    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCareerLoading(false));
  }
};

/**
 * To career from server
 */
export const getCareerById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeCareerLoading(true));

    // API Fetching
    const resp = await httpCareer().get(
      language === "ENGLISH"
        ? ApiEndpoints.careerEn + "/" + id
        : ApiEndpoints.careerAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setCareerById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCareerLoading(false));
  }
};

/**
 * To add a new  career
 */
export const addCareer = async (careerData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeCareerLoading(true));

    // API Fetching
    const resp = await httpCareer("multipart/form-data").post(
      language === "ENGLISH" ? ApiEndpoints.careerEn : ApiEndpoints.careerAr,
      careerData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addCareerState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCareerLoading(false));
  }
};

/**
 * To edit a new  career
 */
export const editCareer = async (id, careerData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeCareerLoading(true));

    // API Fetching
    const resp = await httpCareer("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.careerEn + "/" + id
        : ApiEndpoints.careerAr + "/" + id,
      careerData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editCareerState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCareerLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeCareerVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeCareerLoading(true));

    // API Fetching
    const resp = await httpCareer().patch(
      language === "ENGLISH"
        ? ApiEndpoints.careerVisibilityEn + id
        : ApiEndpoints.careerVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeCareerVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCareerLoading(false));
  }
};

/**
 * To delete the career
 */
export const deleteCareer = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeCareerLoading(true));

    // API Fetching
    const resp = await httpCareer().delete(
      language === "ENGLISH"
        ? ApiEndpoints.careerEn + "/" + id
        : ApiEndpoints.careerAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteCareerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeCareerLoading(false));
  }
};
