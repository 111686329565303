import React from "react";
import { Link } from "react-router-dom";
import {
  BsFillArchiveFill,
  BsPeopleFill,
  BsFillChatSquareQuoteFill,
} from "react-icons/bs";
import { IoNewspaperSharp } from "react-icons/io5";
import { AiOutlineUsergroupDelete } from "react-icons/ai";
import { FaMoneyCheck, FaHandsHelping, FaAward } from "react-icons/fa";
import { MdFactory, MdHomeRepairService, MdContactMail } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { KRoutes } from "../routers/routers";
import { useSelector } from "react-redux";

const Sidebar = ({ title, openSidebarToggle, OpenSidebar }) => {
  const { profile } = useSelector((state) => state.admin);
  return (
    <aside
      id="sidebarnormal"
      className={openSidebarToggle ? "sidebar-responsivenormal" : ""}
    >
      <div className="sidebar-titlenormal">
        <div className="sidebar-brandnormal">
          <img src="/logo.png" width="120px" height="50px" />
        </div>
        <span className="icon close_iconnormal" onClick={OpenSidebar}>
          X
        </span>
      </div>

      <ul className="sidebar-listnormal">
        {profile && profile.role && profile.role === "" && (
          <Link to={KRoutes.admins}>
            <li className="sidebar-list-itemnormal">
              <RiAdminFill
                className={`iconnormal ${
                  title === "admins" ? "active-itemnormal" : ""
                }`}
              />{" "}
              Admins
            </li>
          </Link>
        )}

        <Link to={KRoutes.awards}>
          <li className="sidebar-list-itemnormal">
            <FaAward
              className={`iconnormal ${
                title === "awards" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Awards
          </li>
        </Link>

        <Link to={KRoutes.careers}>
          <li className="sidebar-list-itemnormal">
            <FaMoneyCheck
              className={`iconnormal ${
                title === "career" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Career
          </li>
        </Link>

        <Link to={KRoutes.clients}>
          <li className="sidebar-list-itemnormal">
            <AiOutlineUsergroupDelete
              className={`iconnormal ${
                title === "clientslogos" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Clients
          </li>
        </Link>

        <Link to={KRoutes.contacts}>
          <li className="sidebar-list-itemnormal">
            <MdContactMail
              className={`iconnormal ${
                title === "contact" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Contacts
          </li>
        </Link>

        <Link to={KRoutes.industries}>
          <li className="sidebar-list-itemnormal">
            <MdFactory
              className={`iconnormal ${
                title === "industrieslogos" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Industries
          </li>
        </Link>

        <Link to={KRoutes.insightNews}>
          <li className="sidebar-list-itemnormal">
            <IoNewspaperSharp
              className={`iconnormal ${
                title === "insightnews" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Insight News
          </li>
        </Link>

        {/* <Link to={KRoutes.insightVideos}>
          <li className="sidebar-list-itemnormal">
            <GoVideo
              className={`iconnormal ${
                title === "insightvideos" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Insight Videos
          </li>
        </Link> */}

        <Link to={KRoutes.partners}>
          <li className="sidebar-list-itemnormal">
            <FaHandsHelping
              className={`iconnormal ${
                title === "partnerslogos" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Partners
          </li>
        </Link>

        <Link to={KRoutes.services}>
          <li className="sidebar-list-itemnormal">
            <MdHomeRepairService
              className={`iconnormal ${
                title === "services" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Services
          </li>
        </Link>

        <Link to={KRoutes.solutions}>
          <li className="sidebar-list-itemnormal">
            <BsFillArchiveFill
              className={`iconnormal ${
                title === "solutions" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Solutions
          </li>
        </Link>

        <Link to={KRoutes.subscribers}>
          <li className="sidebar-list-itemnormal">
            <BsPeopleFill
              className={`iconnormal ${
                title === "subscribers" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Subscribers
          </li>
        </Link>

        <Link to={KRoutes.testimonials}>
          <li className="sidebar-list-itemnormal">
            <BsFillChatSquareQuoteFill
              className={`iconnormal ${
                title === "testimonials" ? "active-itemnormal" : ""
              }`}
            />{" "}
            Testimonials
          </li>
        </Link>
      </ul>
    </aside>
  );
};

export default Sidebar;
