// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorcontainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  overflow: hidden;
}
.errorcontainer .container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.errorcontainer a {
  text-decoration: none;
  color: #ffffff;
}
.errorcontainer h1 {
  font-size: 72px;
  color: #ffffff;
  margin-bottom: 20px;
}
.errorcontainer p {
  font-size: 24px;
  color: #7f8c8d;
  margin-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/styles/error.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACJ;AAEE;EACE,qBAAA;EACA,cAAA;AAAJ;AAGE;EACE,eAAA;EACA,cAAA;EACA,mBAAA;AADJ;AAIE;EACE,eAAA;EACA,cAAA;EACA,mBAAA;AAFJ","sourcesContent":[".errorcontainer {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #000000;\n  overflow: hidden;\n\n  .container {\n    width: 100%;\n    margin: 0 auto;\n    text-align: center;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n\n  a {\n    text-decoration: none;\n    color: #ffffff;\n  }\n\n  h1 {\n    font-size: 72px;\n    color: #ffffff;\n    margin-bottom: 20px;\n  }\n\n  p {\n    font-size: 24px;\n    color: #7f8c8d;\n    margin-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
