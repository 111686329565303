import React, { useEffect, useState } from "react";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import {
  addAwardBody,
  editAwardBody,
  getAwardBodyById,
} from "../../../services/awardBodyServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddAwardBody = () => {
  const navigate = useNavigate();
  const { language } = useSelector((state) => state.admin);
  let { awardId, awardBodyId } = useParams();
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [awardBodyImage, setAwardBodyImage] = useState();

  useEffect(() => {
    const getAwardBody = async () => {
      if (awardId && awardBodyId) {
        const awardBody = await getAwardBodyById(awardBodyId, language);
        setDescription(awardBody.description);
        setAwardBodyImage(ApiEndpoints.imageURL + awardBody.image);
      }
    };
    getAwardBody();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("awardId", awardId);
      formData.append("description", description);
      formData.append("image", selectedImage);

      let resp = false;
      if (awardId && awardBodyId) {
        resp = await editAwardBody(awardBodyId, formData, language);
      } else {
        resp = await addAwardBody(formData, language);
      }
      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };

  const handleClick = () => {
    navigate(KRoutes.awardBodies + awardId);
  };

  return (
    <Layout title="awards">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / {awardId && awardBodyId ? "Edit" : "Add"} Inner Award</h1>
        </div>

        <div className="fixed bg-gray-500  flex items-center justify-center">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Inner Award Details</h2>

            <label htmlFor="awardDescription" className="block mb-2">
              Inner Award Description:
            </label>
            <textarea
              id="awardDescription"
              value={description}
              onChange={handleDescriptionChange}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />

            <input type="file" onChange={handleImageChange} />
            {(selectedImage || awardBodyImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : awardBodyImage
                }
                alt="Selected Partner"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddAwardBody;
