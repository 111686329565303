import { createSlice } from "@reduxjs/toolkit";

const testimonialInitState = {
  isLoading: false,
  testimonials: [],
  testimonial: {},
};

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: testimonialInitState,
  reducers: {
    changeTestimonialLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setTestimonialById: (state, action) => {
      state.testimonial = action.payload;
      state.isLoading = false;
    },
    setTestimonialsState: (state, action) => {
      if (state.testimonials.length <= 0 || action.payload.refresh) {
        state.testimonials = action.payload.testimonials || state.testimonials;
      }
      state.isLoading = false;
    },
    addTestimonialState: (state, action) => {
      state.testimonials.unshift(action.payload);
      state.isLoading = false;
    },
    editTestimonialState: (state, action) => {
      state.testimonials = state.testimonials.map((testimonial) => {
        if (action.payload.id === testimonial.id) {
          testimonial = action.payload;
        }
        return testimonial;
      });
      state.isLoading = false;
    },
    changeTestimonialVisibilityState: (state, action) => {
      state.testimonials = state.testimonials.map((testimonial) => {
        if (
          action.payload.id === testimonial.id &&
          action.payload.visibility !== testimonial.visibility
        ) {
          testimonial.visibility = action.payload.visibility;
        }
        return testimonial;
      });
      state.isLoading = false;
    },
    deleteTestimonialState: (state, action) => {
      state.testimonials = state.testimonials.filter(
        (testimonial) => testimonial.id !== action.payload
      );
      state.isLoading = false;
    },
    clearTestimonialState: (state) => {
      state = testimonialInitState;
    },
  },
});

export const {
  changeTestimonialLoading,
  setTestimonialsState,
  setTestimonialById,
  addTestimonialState,
  editTestimonialState,
  changeTestimonialVisibilityState,
  deleteTestimonialState,
  clearTestimonialState,
} = testimonialSlice.actions;

export default testimonialSlice.reducer;
