import React, { useEffect, useState } from "react";
import { KRoutes } from "../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../components/Layout";
import {
  addAdmin,
  editAdmin,
  getAdminById,
} from "../../services/adminServices";
import { useSelector } from "react-redux";
import ApiEndpoints from "../../core/apiEndPoints";

const ScreenAddAdmins = () => {
  const navigate = useNavigate();
  const { adminId } = useParams();
  const { language } = useSelector((state) => state.admin);
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const getAdmin = async () => {
      if (adminId) {
        const admin = await getAdminById(adminId, language);
        setName(admin.name);
        setUserName(admin.username);
        setEmail(admin.password);
      }
    };
    getAdmin();
  }, []);

  const handleSubmit = async () => {
    try {
      const admin = {
        name,
        username,
        email,
        password,
      };
      let resp = false;
      if (adminId) {
        resp = await editAdmin(adminId, admin);
      } else {
        resp = await addAdmin(admin);
      }

      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting admin:", error);
    }
  };
  const handleClick = () => {
    navigate(KRoutes.admins);
  };

  return (
    <Layout title="adminslogos">
      <div className="testimonialslogosmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / {adminId ? "Edit" : "Add"} Admin</h1>
        </div>

        <div className=" bg-white-500  flex items-start justify-start">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Admin Details</h2>
            <label htmlFor="name" className="block mb-2">
              Admin Name:
            </label>
            <input
              type="text"
              style={{ width: "100%" }}
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />
            <label htmlFor="name" className="block mb-2">
              Admin Username:
            </label>
            <input
              type="text"
              style={{ width: "100%" }}
              id="username"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />
            <label htmlFor="name" className="block mb-2">
              Admin Email:
            </label>
            <input
              type="text"
              style={{ width: "100%" }}
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />
            <label htmlFor="name" className="block mb-2">
              Admin Password:
            </label>
            <input
              type="text"
              style={{ width: "100%" }}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddAdmins;
