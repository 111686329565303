import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import {
  addInsightNews,
  editInsightNews,
  getInsightNewsById,
} from "../../../services/insightNewsServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddInsightNews = () => {
  const navigate = useNavigate();
  const { insightNewsId } = useParams();
  const { language } = useSelector((state) => state.admin);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [insightNewsImage, setInsightNewsImage] = useState(null);

  useEffect(() => {
    const getInsightNews = async () => {
      if (insightNewsId) {
        const insightNews = await getInsightNewsById(insightNewsId, language);
        setTitle(insightNews.title);
        setDescription(insightNews.description);
        setInsightNewsImage(ApiEndpoints.imageURL + insightNews.image);
      }
    };
    getInsightNews();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setInsightNewsImage(null);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("image", selectedImage);

      let resp = false;
      if (insightNewsId) {
        resp = await editInsightNews(insightNewsId, formData, language);
      } else {
        resp = await addInsightNews(formData, language);
      }

      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };

  const handleClick = () => {
    navigate(KRoutes.insightNews);
  };

  return (
    <Layout title="insightnews">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / {insightNewsId ? "Edit" : "Add"} InsightNews</h1>
        </div>

        <div className=" bg-white-500  flex items-start justify-start">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Insight News Details</h2>
            <label htmlFor="insightNewsTitle" className="block mb-2">
              Insight News Title:
            </label>
            <input
              type="text"
              id="insightNewsTitle"
              style={{ width: "100%" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="insightNewsDescription" className="block mb-2">
              insightNews Description:
            </label>
            <ReactQuill
              id="insightNewsDescription"
              value={description}
              onChange={(value) => setDescription(value)}
              className="mb-8"
              style={{ height: "200px", width: "700px" }}
            />

            <br />
            <input type="file" onChange={handleImageChange} />
            {(selectedImage || insightNewsImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : insightNewsImage
                }
                alt="Selected insightNews"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddInsightNews;
