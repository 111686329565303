import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeAwardBodyLoading,
  setAwardBodiesState,
  setAwardBodyById,
  addAwardBodyState,
  editAwardBodyState,
  changeAwardBodyVisibilityState,
  deleteAwardBodyState,
} from "../redux/awardBodyReducer";

/**
 * To get a  awardBodies from server
 */
export const getAwardBodiesByAwardId = async (
  id,
  language = "ENGLISH",
  refresh = false
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAwardBodyLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.awardBodysEn + id
        : ApiEndpoints.awardBodysAr + id,
      { params: { limit: -1 } }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAwardBodiesState({ awardBodies: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardBodyLoading(false));
  }
};

/**
 * To awardBody from server
 */
export const getAwardBodyById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardBodyLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.awardBodyEn + "/" + id
        : ApiEndpoints.awardBodyAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAwardBodyById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardBodyLoading(false));
  }
};

/**
 * To add a new  awardBody
 */
export const addAwardBody = async (awardBodyData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardBodyLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH"
        ? ApiEndpoints.awardBodyEn
        : ApiEndpoints.awardBodyAr,
      awardBodyData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addAwardBodyState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardBodyLoading(false));
  }
};

/**
 * To edit a new  awardBody
 */
export const editAwardBody = async (
  id,
  awardBodyData,
  language = "ENGLISH"
) => {
  try {
    // Set Loading
    dispatch(changeAwardBodyLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.awardBodyEn + "/" + id
        : ApiEndpoints.awardBodyAr + "/" + id,
      awardBodyData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editAwardBodyState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardBodyLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeAwardBodyVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardBodyLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.awardBodyVisibilityEn + id
        : ApiEndpoints.awardBodyVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeAwardBodyVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardBodyLoading(false));
  }
};

/**
 * To delete the awardBody
 */
export const deleteAwardBody = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeAwardBodyLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.awardBodyEn + "/" + id
        : ApiEndpoints.awardBodyAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteAwardBodyState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAwardBodyLoading(false));
  }
};
