import React, { useEffect, useState } from "react";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import {
  addSolutionBody,
  editSolutionBody,
  getSolutionBodyById,
} from "../../../services/solutionBodyServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddSolutionBody = () => {
  const { language } = useSelector((state) => state.admin);
  const { solutionId, solutionBodyId } = useParams();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [solutionBodyImage, setSolutionBodyImage] = useState();

  useEffect(() => {
    const getSolutionBody = async () => {
      if (solutionId && solutionBodyId) {
        const solutionBody = await getSolutionBodyById(
          solutionBodyId,
          language
        );
        setTitle(solutionBody.title);
        setDescription(solutionBody.description);
        setSolutionBodyImage(ApiEndpoints.imageURL + solutionBody.image);
      }
    };
    getSolutionBody();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("solutionId", solutionId);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("image", selectedImage);

      let resp = false;
      if (solutionId && solutionBodyId) {
        resp = await editSolutionBody(solutionBodyId, formData, language);
      } else {
        resp = await addSolutionBody(formData, language);
      }
      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };
  const handleClick = () => {
    navigate(KRoutes.solutionBodies + solutionId);
  };

  return (
    <Layout title="solutions">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / Add Solution body</h1>
        </div>

        <div className="fixed bg-gray-500  flex items-center justify-center">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Solution Body Details</h2>

            <label htmlFor="AwardId" className="block mb-2">
              Title:
            </label>
            <input
              type="text"
              id="SolutionTitle"
              value={title}
              onChange={handleTitleChange}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="solutionDescription" className="block mb-2">
              Solution Body Description:
            </label>
            <textarea
              id="solutionDescription"
              value={description}
              onChange={handleDescriptionChange}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />

            <input type="file" onChange={handleImageChange} />
            {(selectedImage || solutionBodyImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : solutionBodyImage
                }
                alt="Selected solution"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddSolutionBody;
