import { createSlice } from "@reduxjs/toolkit";

const contactInitState = {
  isLoading: false,
  contacts: [],
  contact: {},
};

const contactSlice = createSlice({
  name: "contact",
  initialState: contactInitState,
  reducers: {
    changeContactLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setContactById: (state, action) => {
      state.contact = action.payload;
      state.isLoading = false;
    },
    setContactsState: (state, action) => {
      if (state.contacts.length <= 0 || action.payload.refresh) {
        state.contacts = action.payload.contacts || state.contacts;
      }
      state.isLoading = false;
    },
    addContactState: (state, action) => {
      state.contacts.unshift(action.payload);
      state.isLoading = false;
    },
    editContactState: (state, action) => {
      state.contacts = state.contacts.map((contact) => {
        if (action.payload.id === contact.id) {
          contact = action.payload;
        }
        return contact;
      });
      state.isLoading = false;
    },
    deleteContactState: (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== action.payload
      );
      state.isLoading = false;
    },
    clearContactState: (state) => {
      state = contactInitState;
    },
  },
});

export const {
  changeContactLoading,
  setContactsState,
  setContactById,
  addContactState,
  editContactState,
  changeContactVisibilityState,
  deleteContactState,
  clearContactState,
} = contactSlice.actions;

export default contactSlice.reducer;
