import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndPoints";
import { dispatch } from "../redux/store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../redux/notificationReducer";
import {
  changeIndustryLoading,
  setIndustriesState,
  setIndustryById,
  addIndustryState,
  editIndustryState,
  changeIndustryVisibilityState,
  deleteIndustryState,
} from "../redux/industryReducer";

/**
 * To get a  industries from server
 */
export const getIndustries = async (
  language = "ENGLISH",
  refresh = false,
  query = ""
) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeIndustryLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.industryEn
        : ApiEndpoints.industryAr,
      { params: { name: query.trim(), limit: -1 } }
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setIndustriesState({ industries: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeIndustryLoading(false));
  }
};

/**
 * To industry from server
 */
export const getIndustryById = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeIndustryLoading(true));

    // API Fetching
    const resp = await httpClient().get(
      language === "ENGLISH"
        ? ApiEndpoints.industryEn + "/" + id
        : ApiEndpoints.industryAr + "/" + id
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setIndustryById(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return resp.data.results;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeIndustryLoading(false));
  }
};

/**
 * To add a new  industry
 */
export const addIndustry = async (industryData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeIndustryLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      language === "ENGLISH"
        ? ApiEndpoints.industryEn
        : ApiEndpoints.industryAr,
      industryData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addIndustryState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeIndustryLoading(false));
  }
};

/**
 * To edit a new  industry
 */
export const editIndustry = async (id, industryData, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeIndustryLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      language === "ENGLISH"
        ? ApiEndpoints.industryEn + "/" + id
        : ApiEndpoints.industryAr + "/" + id,
      industryData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editIndustryState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeIndustryLoading(false));
  }
};

/**
 * To change the visbility
 */
export const changeIndustryVisibility = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeIndustryLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      language === "ENGLISH"
        ? ApiEndpoints.industryVisibilityEn + id
        : ApiEndpoints.industryVisibilityAr + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeIndustryVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeIndustryLoading(false));
  }
};

/**
 * To delete the industry
 */
export const deleteIndustry = async (id, language = "ENGLISH") => {
  try {
    // Set Loading
    dispatch(changeIndustryLoading(true));

    // API Fetching
    const resp = await httpClient().delete(
      language === "ENGLISH"
        ? ApiEndpoints.industryEn + "/" + id
        : ApiEndpoints.industryAr + "/" + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteIndustryState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeIndustryLoading(false));
  }
};
