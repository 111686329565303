import { createSlice } from "@reduxjs/toolkit";

const insightVideoInitState = {
  isLoading: false,
  insightVideos: [],
  insightVideo: {},
};

const insightVideoSlice = createSlice({
  name: "insightVideo",
  initialState: insightVideoInitState,
  reducers: {
    changeInsightVideoLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setInsightVideoById: (state, action) => {
      state.insightVideo = action.payload;
      state.isLoading = false;
    },
    setInsightVideosState: (state, action) => {
      if (state.insightVideos.length <= 0 || action.payload.refresh) {
        state.insightVideos =
          action.payload.insightVideos || state.insightVideos;
      }
      state.isLoading = false;
    },
    addInsightVideoState: (state, action) => {
      state.insightVideos.unshift(action.payload);
      state.isLoading = false;
    },
    editInsightVideoState: (state, action) => {
      state.insightVideos = state.insightVideos.map((insightVideo) => {
        if (action.payload.id === insightVideo.id) {
          insightVideo = action.payload;
        }
        return insightVideo;
      });
      state.isLoading = false;
    },
    changeInsightVideoVisibilityState: (state, action) => {
      state.insightVideos = state.insightVideos.map((insightVideo) => {
        if (
          action.payload.id === insightVideo.id &&
          action.payload.visibility !== insightVideo.visibility
        ) {
          insightVideo.visibility = action.payload.visibility;
        }
        return insightVideo;
      });
      state.isLoading = false;
    },
    deleteInsightVideoState: (state, action) => {
      state.insightVideos = state.insightVideos.filter(
        (insightVideo) => insightVideo.id !== action.payload
      );
      state.isLoading = false;
    },
    clearInsightVideoState: (state) => {
      state = insightVideoInitState;
    },
  },
});

export const {
  changeInsightVideoLoading,
  setInsightVideosState,
  setInsightVideoById,
  addInsightVideoState,
  editInsightVideoState,
  changeInsightVideoVisibilityState,
  deleteInsightVideoState,
  clearInsightVideoState,
} = insightVideoSlice.actions;

export default insightVideoSlice.reducer;
