import { createSlice } from "@reduxjs/toolkit";

const serviceInitState = {
  isLoading: false,
  services: [],
  service: {},
};

const serviceSlice = createSlice({
  name: "service",
  initialState: serviceInitState,
  reducers: {
    changeServiceLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setServiceById: (state, action) => {
      state.service = action.payload;
      state.isLoading = false;
    },
    setServicesState: (state, action) => {
      if (state.services.length <= 0 || action.payload.refresh) {
        state.services = action.payload.services || state.services;
      }
      state.isLoading = false;
    },
    addServiceState: (state, action) => {
      state.services.unshift(action.payload);
      state.isLoading = false;
    },
    editServiceState: (state, action) => {
      state.services = state.services.map((service) => {
        if (action.payload.id === service.id) {
          service = action.payload;
        }
        return service;
      });
      state.isLoading = false;
    },
    changeServiceVisibilityState: (state, action) => {
      state.services = state.services.map((service) => {
        if (
          action.payload.id === service.id &&
          action.payload.visibility !== service.visibility
        ) {
          service.visibility = action.payload.visibility;
        }
        return service;
      });
      state.isLoading = false;
    },
    deleteServiceState: (state, action) => {
      state.services = state.services.filter(
        (service) => service.id !== action.payload
      );
      state.isLoading = false;
    },
    clearServiceState: (state) => {
      state = serviceInitState;
    },
  },
});

export const {
  changeServiceLoading,
  setServicesState,
  setServiceById,
  addServiceState,
  editServiceState,
  changeServiceVisibilityState,
  deleteServiceState,
  clearServiceState,
} = serviceSlice.actions;

export default serviceSlice.reducer;
