import axios from "axios";
import LocalStorageKeys from "./localstorageKeys";
import ApiEndpoints from "./apiEndPoints";

const httpClient = (contentType = "application/json",csrfToken = undefined) => {


  // Create intance
  const instance = axios.create({
    baseURL: ApiEndpoints.baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": contentType,
      "Csrf-Token": csrfToken,
    },
    validateStatus: function (status) {
      return status <= 500;
    },
  });

  //   Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem(LocalStorageKeys.adminToken);
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
  return instance;
};

export default httpClient;
