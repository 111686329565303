import React, { useRef, useEffect, useState } from "react";
import "../../styles/login/login.scss";
import "react-toastify/dist/ReactToastify.css";
import { adminLogin } from "../../services/adminServices";
import { useDispatch, useSelector } from "react-redux";
import { setWarningMessage } from "../../redux/notificationReducer";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../../routers/routers";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import ApiEndpoints from "../../core/apiEndPoints";
import axios from "axios";

const ScreenLogin = () => {
  let userNameRef = useRef("");
  const reCaptchaRef = useRef(null);
  let userPasswordRef = useRef("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.admin);
  const [isCsrfToken, setCsrfToken] = useState("");

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState("");

  const handleCaptchaVerify = (response) => {
    setIsCaptchaVerified(true);
    setCaptchaResponse(response);
  };

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(ApiEndpoints.baseURL + "/csrf-token", {
          withCredentials: true,
        });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(KRoutes.awards);
    }
  }, [isLoggedIn]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const admin = {
      username: userNameRef.current.value,
      password: userPasswordRef.current.value,
      captchaResponse,
    };

    if (!admin.username || !admin.password) {
      return dispatch(setWarningMessage("Provide username and password"));
    }

    if (!isCsrfToken) {
      return dispatch(
        setWarningMessage(
          "Something went wrong. Please refresh the page and try again."
        )
      );
    }

    if (isCaptchaVerified) {
      adminLogin(admin, isCsrfToken);
    } else {
      dispatch(setWarningMessage("reCAPTCHA"));
    }
  };

  return (
    <div className="logincontainermain">
      <Helmet>
        <meta http-equiv="X-Frame-Options" content="DENY" />
        <meta
          http-equiv="Content-Security-Policy"
          content="frame-ancestors 'none'"
        />
        <meta http-equiv="X-Powered-By" content="" />
        <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
      </Helmet>

      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="parentdivinputcontainer">
          <img src="/logo.png" width="150px" height="60px" />
          <h1>Login</h1>
          <div className="formchild">
            <label className="label">User name *</label>
            <br />
            <input
              type="text"
              name="name"
              minLength={2}
              maxLength={200}
              ref={userNameRef}
            />
          </div>
        </div>

        <div className="parentdivinputcontainer">
          <div className="formchild">
            <label className="label">Password *</label>
            <br />
            <input
              type="password"
              name="password"
              minLength={2}
              maxLength={100}
              ref={userPasswordRef}
            />
          </div>
        </div>

        <input id="buttoninput" type="submit" value="Submit"></input>
        <br />
        <div>
          <br />
          <ReCAPTCHA
            sitekey={ApiEndpoints.SITEKEY}
            onChange={handleCaptchaVerify}
            ref={reCaptchaRef}
          />
        </div>
      </form>
    </div>
  );
};

export default ScreenLogin;
