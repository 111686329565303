import React, { useEffect, useState } from "react";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import {
  addTestimonial,
  editTestimonial,
  getTestimonialById,
} from "../../../services/testimonialServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddTestimonial = () => {
  const navigate = useNavigate();
  const { testimonialId } = useParams();
  const { language } = useSelector((state) => state.admin);

  const [testimonialTitle, setTestimonialTitle] = useState("");
  const [testimonialSubTitle, setTestimonialSubTitle] = useState("");
  const [testimonialDescription, setTestimonialDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [testimonialImage, setTestimonialImage] = useState();

  useEffect(() => {
    const getTestimonial = async () => {
      if (testimonialId) {
        const testimonial = await getTestimonialById(testimonialId, language);
        setTestimonialTitle(testimonial.title);
        setTestimonialSubTitle(testimonial.subTitle);
        setTestimonialDescription(testimonial.description);
        setTestimonialImage(ApiEndpoints.imageURL + testimonial.image);
      }
    };
    getTestimonial();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setTestimonialImage(null);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", testimonialTitle);
      formData.append("subTitle", testimonialSubTitle);
      formData.append("description", testimonialDescription);
      formData.append("image", selectedImage);

      let resp = false;
      if (testimonialId) {
        resp = await editTestimonial(testimonialId, formData, language);
      } else {
        resp = await addTestimonial(formData, language);
      }

      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };

  const handleClick = () => {
    navigate(KRoutes.testimonials);
  };

  return (
    <Layout title="testimonials">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / {testimonialId ? "Edit" : "Add"} Testimonial</h1>
        </div>

        <div className="bg-white-500  flex items-start justify-start">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Testimonial Details</h2>
            <label htmlFor="testimonialTitle" className="block mb-2">
              Testimonial Title:
            </label>
            <input
              type="text"
              id="testimonialTitle"
              value={testimonialTitle}
              style={{ width: "100%" }}
              onChange={(e) => setTestimonialTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <label htmlFor="testimonialSubTitle" className="block mb-2">
              Testimonial SubTitle:
            </label>
            <input
              type="text"
              style={{ width: "100%" }}
              id="testimonialSubTitle"
              value={testimonialSubTitle}
              onChange={(e) => setTestimonialSubTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <label htmlFor="testimonialDescription" className="block mb-2">
              Testimonial Description:
            </label>
            <textarea
              id="testimonialDescription"
              value={testimonialDescription}
              style={{ width: "100%" }}
              onChange={(e) => setTestimonialDescription(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />
            <input type="file" onChange={handleImageChange} />
            {(selectedImage || testimonialImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : testimonialImage
                }
                alt="Selected testimonial"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddTestimonial;
