import React, { useEffect } from "react";
import Router from "./routers/routers";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { resetAllMessage } from "./redux/notificationReducer";
import "react-toastify/dist/ReactToastify.css";
import { getAdminProfile } from "./services/adminServices";

const App = () => {
  const { hasError, hasNotification, hasSuccess, hasWarning, message } =
    useSelector((state) => state.notification);
  const { profile, isLoggedIn } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && !profile) {
      getAdminProfile();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (hasNotification && message.length > 0) {
      toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(resetAllMessage());
    }
    if (hasWarning && message.length > 0) {
      toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(resetAllMessage());
    }
    if (hasError && message.length > 0) {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(resetAllMessage());
    }
    if (hasSuccess && message.length > 0) {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(resetAllMessage());
    }
  }, [hasError, hasNotification, hasSuccess, hasWarning, message, dispatch]);

  return (
    <div className="app">
      <ToastContainer />
      <Router />
    </div>
  );
};

export default App;
