import React, { useEffect, useState } from "react";
import { KRoutes } from "../../../routers/routers";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import {
  addAward,
  editAward,
  getAwardById,
} from "../../../services/awardServices";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";

const ScreenAddAward = () => {
  const navigate = useNavigate();
  const { awardId } = useParams();
  const { language } = useSelector((state) => state.admin);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [awardImage, setAwardImage] = useState(null);

  useEffect(() => {
    const getAward = async () => {
      if (awardId) {
        const award = await getAwardById(awardId, language);
        setTitle(award.title);
        setSubTitle(award.subTitle);
        setDescription(award.description);
        setAwardImage(ApiEndpoints.imageURL + award.image);
      }
    };
    getAward();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("subTitle", subTitle);
      formData.append("description", description);
      formData.append("image", selectedImage);

      let resp = false;
      if (awardId) {
        resp = await editAward(awardId, formData, language);
      } else {
        resp = await addAward(formData, language);
      }

      if (resp) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };
  const handleClick = () => {
    navigate(KRoutes.awards);
  };

  return (
    <Layout title="awards">
      <div className="partnersmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / {awardId ? "Edit" : "Add"} Award</h1>
        </div>

        <div className="bg-white-500  flex items-start justify-start">
          <div className="bg-white p-8 rounded">
            <h2 className="text-2xl mb-4">Award Details</h2>

            <label htmlFor="AwardTitle" className="block mb-2">
              Award Title:
            </label>
            <input
              type="text"
              id="AwardTitle"
              style={{ width: "100%" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="AwardSubTitle" className="block mb-2">
              Subtitle:
            </label>
            <input
              type="text"
              id="subtitle"
              style={{ width: "100%" }}
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />

            <label htmlFor="awardDescription" className="block mb-2">
              Award Description:
            </label>
            <textarea
              id="awardDescription"
              cols={150}
              rows={7}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border border-gray-400 p-2 mb-4"
            />
            <br />

            <input type="file" onChange={handleImageChange} />
            {(selectedImage || awardImage) && (
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : awardImage
                }
                alt="Selected Partner"
                className="my-4"
                width="100px"
                height="80px"
              />
            )}
            <br />
            <br />

            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white py-2 px-5"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAddAward;
