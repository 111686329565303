import { createSlice } from "@reduxjs/toolkit";

const notificationInitState = {
  prevMessage: "",
  prevMessageAt: new Date(),
  message: "",
  hasError: false,
  hasSuccess: false,
  hasWarning: false,
  hasNotification: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitState,
  reducers: {
    setErrorMessage: (state, action) => {
      // if(state.prevMessage.toString() !== action.payload.toString()){
        state.message = action.payload;
        state.hasError = true;
      // }
    },
    resetErrorMessage: (state) => {
      state.message = "";
      state.hasError = false;
    },
    setSuccessMessage: (state, action) => {
      // if(state.prevMessage.toString() !== action.payload.toString()){
        state.message = action.payload;
        state.hasSuccess = true;
      // }
    },
    resetSuccessMessage: (state) => {
      state.message = "";
      state.hasSuccess = false;
    },
    setWarningMessage: (state, action) => {
      // if(state.prevMessage.toString() !== action.payload.toString()){
        state.message = action.payload;
        state.hasWarning = true;
      // }
    },
    resetWarningMessage: (state) => {
      state.message = "";
      state.hasWarning = false;
    },
    setNotificationMessage: (state, action) => {
      // if(state.prevMessage.toString() !== action.payload.toString()){
        state.message = action.payload;
        state.hasNotification = true;
      // }
    },
    resetNotificationMessage: (state) => {
      state.message = "";
      state.hasNotification = false;
    },
    resetAllMessage: (state) => {
      // if(state.prevMessage !== state.message) {
      //   state.prevMessage = state.message;
      //   state.prevMessageAt = new Date();
      // }
      state.message = "";
      state.hasError = false;
      state.hasSuccess = false;
      state.hasWarning = false;
      state.hasNotification = false;
    },
  },
});

export const {
  setErrorMessage,
  resetErrorMessage,
  setSuccessMessage,
  resetSuccessMessage,
  setWarningMessage,
  resetWarningMessage,
  setNotificationMessage,
  resetNotificationMessage,
  resetAllMessage,
} = notificationSlice.actions;
export default notificationSlice.reducer;
