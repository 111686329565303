import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Layout from "../../../components/Layout";
import { KRoutes } from "../../../routers/routers";
import ApiEndpoints from "../../../core/apiEndPoints";
import { useSelector } from "react-redux";
import {
  changeAwardBodyVisibility,
  deleteAwardBody,
  getAwardBodiesByAwardId,
} from "../../../services/awardBodyServices";
import { dispatch } from "../../../redux/store";
import { clearSolutionState } from "../../../redux/solutionReducer";

const ScreenAwardBodies = () => {
  const navigate = useNavigate();
  let { awardId } = useParams();
  const { awardBodies } = useSelector((state) => state.awardBody);
  const { language } = useSelector((state) => state.admin);

  useEffect(() => {
    const fetchData = () => {
      if (language) {
        dispatch(clearSolutionState());
        getAwardBodiesByAwardId(awardId, language, true);
      }
    };
    fetchData();
  }, []);

  const handleClick = () => {
    navigate(KRoutes.awards);
  };

  const handleInnerPage = () => {
    navigate(KRoutes.addAwardBody + awardId);
  };

  const handleEditClick = (awardBodyId) => {
    navigate(KRoutes.editAwardBody + awardId + "/" + awardBodyId);
  };

  return (
    <Layout title="awards">
      <div className="careerlogosmaincontainer">
        <div className="linkheadingcontainer">
          <button onClick={handleClick}>
            <BiArrowBack size={"2em"} />
          </button>
          <h1>Admin / Awards / View Inner Awards </h1>
        </div>
        <div>
          <section className="p-3 sm:p-5">
            <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
              <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-end justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                  <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleInnerPage}
                      className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover-bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                    >
                      <svg
                        className="h-3.5 w-3.5 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clip-rule="evenodd"
                          fill-rule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        />
                      </svg>
                      Add Award Body
                    </button>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          Sl. No.
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Award Title
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Description
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Image
                        </th>

                        <th scope="col" className="px-4 py-3">
                          Visibility
                        </th>

                        <th scope="col" className="px-4 py-3 text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {awardBodies.map((data, index) => {
                        return (
                          <tr
                            className="border-b dark:border-gray-700"
                            key={data.id}
                          >
                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {index + 1}
                            </th>

                            <td className="px-4 py-3">{data.award.title}</td>

                            <td className="px-4 py-3">
                              {data.description.length > 15
                                ? data.description.substring(0, 15) + "..."
                                : data.description}
                            </td>

                            <td className="px-4 py-3">
                              <img
                                width="40px"
                                height="40px"
                                src={ApiEndpoints.imageURL + data.image}
                              />
                            </td>

                            <td className="px-4 py-3">
                              <span
                                class={` text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-${
                                  data.visibility === "Show" ? "green" : "red"
                                }-500 text-white`}
                              >
                                {data.visibility}
                              </span>
                            </td>

                            <td className="px-4 py-3 flex justify-center">
                              <button
                                type="button"
                                className="text-green-600 mx-2 inline-flex items-center hover:text-white border border-green-600 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover-bg-green-600 dark:focus:ring-green-900"
                                onClick={() => handleEditClick(data.id)}
                              >
                                <svg
                                  className="mr-1 -ml-1 w-5 h-5"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M13 2a1 1 0 011 1v11a1 1 0 01-2 0V5.414L6.707 11.707a1 1 0 01-1.414-1.414L11 4.586V2a1 1 0 011-1z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                Edit
                              </button>

                              <button
                                type="button"
                                className={`mx-2 text-${
                                  data.visibility === "Show" ? "red" : "green"
                                }-600 inline-flex items-center hover:text-white border border-${
                                  data.visibility === "Show" ? "red" : "green"
                                }-600 hover:bg-${
                                  data.visibility === "Show" ? "red" : "green"
                                }-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-${
                                  data.visibility === "Show" ? "red" : "green"
                                }-500 dark:text-${
                                  data.visibility === "Show" ? "red" : "green"
                                }-500 dark:hover:text-white dark:hover-bg-${
                                  data.visibility === "Show" ? "red" : "green"
                                }-600 dark:focus:ring-${
                                  data.visibility === "Show" ? "red" : "green"
                                }-900`}
                                onClick={() =>
                                  changeAwardBodyVisibility(data.id, language)
                                }
                              >
                                {data.visibility === "Show" ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="mr-1 -ml-1 w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0zM4.93 4.93a9 9 0 0114.14 14.14l-14.14-14.14z"
                                    ></path>
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M1 1l22 22"
                                    ></path>
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="mr-1 -ml-1 w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    ></path>
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M2 12s3-8 10-8 10 8 10 8-3 8-10 8-10-8-10-8z"
                                    ></path>
                                  </svg>
                                )}
                                {data.visibility === "Show" ? "Hide" : "Show"}
                              </button>

                              <button
                                type="button"
                                className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover-bg-red-600 dark:focus:ring-red-900"
                                onClick={() =>
                                  deleteAwardBody(data.id, language)
                                }
                              >
                                <svg
                                  className="mr-1 -ml-1 w-5 h-5"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <nav
                  className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
                  aria-label="Table navigation"
                ></nav>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAwardBodies;
