import { createSlice } from "@reduxjs/toolkit";

const awardBodyInitState = {
  isLoading: false,
  awardBodies: [],
  awardBody: {},
};

const awardBodySlice = createSlice({
  name: "award-body",
  initialState: awardBodyInitState,
  reducers: {
    changeAwardBodyLoading: (state, action) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setAwardBodyById: (state, action) => {
      state.awardBody = action.payload;
      state.isLoading = false;
    },
    setAwardBodiesState: (state, action) => {
      if (state.awardBodies.length <= 0 || action.payload.refresh) {
        state.awardBodies = action.payload.awardBodies || state.awardBodies;
      }
      state.isLoading = false;
    },
    addAwardBodyState: (state, action) => {
      state.awardBodies.unshift(action.payload);
      state.isLoading = false;
    },
    editAwardBodyState: (state, action) => {
      state.awardBodies = state.awardBodies.map((awardBody) => {
        if (action.payload.id === awardBody.id) {
          awardBody = action.payload;
        }
        return awardBody;
      });
      state.isLoading = false;
    },
    changeAwardBodyVisibilityState: (state, action) => {
      state.awardBodies = state.awardBodies.map((awardBody) => {
        if (
          action.payload.id === awardBody.id &&
          action.payload.visibility !== awardBody.visibility
        ) {
          awardBody.visibility = action.payload.visibility;
        }
        return awardBody;
      });
      state.isLoading = false;
    },
    deleteAwardBodyState: (state, action) => {
      state.awardBodies = state.awardBodies.filter(
        (awardBody) => awardBody.id !== action.payload
      );
      state.isLoading = false;
    },
    clearAwardBodyState: (state) => {
      state = awardBodyInitState;
    },
  },
});

export const {
  changeAwardBodyLoading,
  setAwardBodiesState,
  setAwardBodyById,
  addAwardBodyState,
  editAwardBodyState,
  changeAwardBodyVisibilityState,
  deleteAwardBodyState,
  clearAwardBodyState,
} = awardBodySlice.actions;

export default awardBodySlice.reducer;
